import {
  Box,
  Grid,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  Stack,
  Card,
  CardHeader,
  CardActions,
  Avatar,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SchoolIcon from "@mui/icons-material/School";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import { googleReview } from "../DoctorPage/EMRPage/EMRPage.slice";
import { red } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import {
  registerDoctor,
  updateDoctor,
  uploadProfile,
} from "./doctorRegistration.slice";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useRef } from "react";
import imageCompression from "browser-image-compression";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const deleteImage = {
  position: "absolute",
  top: 0,
  right: 0,
  cutser: "pointer",
  border: 0,
  backgroundColor: "#958c8c",
  color: "#fff",
};

const previewStyling = {
  margin: "1rem .5rem",
  position: "relative",
  boxShadow: "rgba(0,0,0,0.05) 0 1px 2px 0",
};
const DoctorProfileUpdate = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([[], []]);

  // useEffect(() => {
  //   console.log(selectedImages);
  // });

  const handleDeleteImage = (profileIndex, index) => {
    const newImages = [...selectedImages];
    const removedImage = newImages[profileIndex].splice(index, 1)[0];

    // Revoke the URL to free up memory
    URL.revokeObjectURL(removedImage.url);

    setSelectedImages(newImages);
  };

  const [formData, setFormData] = useState({
    doc_first_name: "",
    doc_last_name: "",
    age: "",
    email_id: "",
    mobile_number: "",
    password: "",
    doc_degree: "",
    doc_specialization: "",
    doc_department: "",
    doc_working_days: "",
    doc_licence_no: "",
    avg_consultation_time: "",
    consultation_start_time: dayjs().format("HH:mm:ss"),
    consultation_end_time: dayjs().format("HH:mm:ss"),
    consultation_fees: "",
    follow_up_fees: "",
    commonly_treats: "",
    years_of_experience: "",
    bio: "",
    about: "",
    education: [],
    awards: "",
    languages: "",
    educational_content: "",
    external_hips: [],
    google_reviews: [],
    affiliated: true,
  });
  const date = new Date();
  const [error, setError] = useState(false);
  const [education, setEducation] = useState({
    college: "",
    degree: "",
    year: "",
  });

  const dispatch = useDispatch();

  const [externalHips, setExternalHips] = useState({
    name: "",
    mobile_number: "",
    location: "",
    address: "",
    doc_working_days: "",
    consultation_start_time: "",
    consultation_end_time: "",
  });
  const location = useLocation();
  const doctorData = location.state.doctorData;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (doctorData) {
      const updates = {
        doc_first_name: doctorData?.doc_name?.split(" ")[1] || "",
        doc_last_name: doctorData?.doc_name?.split(" ")[2] || "",
        age: doctorData?.age || "",
        email_id: doctorData?.email_id || "",
        mobile_number: doctorData?.mobile_number || "",
        doc_degree: doctorData?.doc_degree || "",
        doc_specialization: doctorData?.doc_specialization || "",
        doc_department: doctorData?.doc_department || "",
        doc_working_days: doctorData?.doc_working_days || "",
        doc_licence_no: doctorData?.doc_licence_no || "",
        avg_consultation_time: doctorData?.avg_consultation_time || "",
        consultation_start_time:
          doctorData?.consultation_start_time || dayjs().format("HH:mm:ss"),
        consultation_end_time:
          doctorData?.consultation_end_time || dayjs().format("HH:mm:ss"),
        consultation_fees: doctorData?.consultation_fees || "",
        follow_up_fees: doctorData?.follow_up_fees || "",
        commonly_treats: doctorData?.commonly_treats || "",
        years_of_experience: doctorData?.years_of_experience || "",
        bio: doctorData?.bio || "",
        about: doctorData?.about || "",
        education: doctorData?.education || [],
        awards: doctorData?.awards || "",
        languages: doctorData.languages || "",
        educational_content: doctorData.educational_content || "",
        external_hips: doctorData.external_hips || [],
        google_reviews: doctorData.google_reviews || [],
        affiliated: doctorData.affiliated || true,
        profile_photo: doctorData.profile_photo || "",
        signature: doctorData.signature || "",
        review_url: doctorData.review_url || "",
        doc_uid: doctorData.doc_uid || "",
        hip_id: doctorData.hip_id || "",
        primary_hip: doctorData.primary_hip || [],
        created_at: doctorData.created_at || "",
        updated_at: doctorData.updated_at || "",
      };

      setFormData((prevState) => ({
        ...prevState,
        ...updates,
      }));
    }
  }, [doctorData]);

  const handleTimeChange = (name, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue ? newValue.format("HH:mm:ss") : "",
    }));
  };

  //Adding Education Value
  const [editIndex, setEditIndex] = useState(null);

  const handleEducationChange = (event) => {
    const { name, value } = event.target;
    setEducation((prevEducation) => ({
      ...prevEducation,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (editIndex !== null) {
      const updatedEducation = formData.education.map((item, index) =>
        index === editIndex ? education : item
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        education: updatedEducation,
      }));
      setEditIndex(null);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        education: [...prevFormData.education, education],
      }));
    }
    setEducation({ college: "", degree: "", year: "" }); // Reset education state after submission
  };

  const handleEditEducation = (index) => {
    setEducation(formData.education[index]);
    setEditIndex(index);
  };

  const handleDeleteEducation = (index) => {
    const updatedEducation = formData.education.filter(
      (_, idx) => idx !== index
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      education: updatedEducation,
    }));
  };
  {
    /* External HIP functionality*/
  }
  const handleExternalHips = (event) => {
    const { name, value } = event.target;
    setExternalHips((prevHips) => ({
      ...prevHips,
      [name]: value,
    }));
  };

  const handleEditHips = (idx) => {
    setExternalHips(formData.external_hips[idx]);
    setEditIndex(idx);
  };

  const handleDeleteHips = (idx) => {
    const updatedHips = formData.external_hips.filter(
      (_, index) => index !== idx
    );
    setFormData((prevValue) => ({
      ...prevValue,
      external_hips: updatedHips,
    }));
  };

  const handleSubmitHip = () => {
    if (editIndex !== null) {
      const updatedHips = formData.external_hips.map((item, idx) =>
        idx === editIndex ? externalHips : item
      );
      setFormData((prevValue) => ({
        ...prevValue,
        external_hips: updatedHips,
      }));
      setEditIndex(null);
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        external_hips: [...prevValues.external_hips, externalHips],
      }));
    }
    setExternalHips({
      name: "",
      mobile_number: "",
      location: "",
      address: "",
      doc_working_days: "",
      consultation_start_time: "",
      consultation_end_time: "",
    });
  };

  const onHandleSubmitFormata = (e) => {
    e.preventDefault();
    // const payload = {
    //   ...formData,
    //   id: "02",
    //   doc_uid: "",
    //   hip_id: "123123",
    // };
    const payload = {
      ...formData,
      id: doctorData.id,
      doc_uid: doctorData.doc_uid,
      hip_id: doctorData.hip_id,
    };

    // Form validation
    if (
      formData.education.length > 0 &&
      // formData.external_hips.length > 0 &&
      formData?.awards.length > 0 &&
      formData?.languages.length > 0 &&
      formData?.google_reviews.length > 0
    ) {
      // Dispatch and handle response
      dispatch(updateDoctor(payload))
        .then((res) => {
          if (res?.payload) {
            // Assuming success is determined by the presence of payload
            navigate(-1);
            setError(false);
          } else {
            // Show error message if update fails
            window.alert(
              "Failed to update doctor information. Please try again."
            );
            setError(true);
          }
        })
        .catch((error) => {
          // Handle any errors during dispatch
          window.alert("An error occurred: " + error.message);
          setError(true);
        });
    } else {
      // Set error if form validation fails
      setError(true);
    }
  };

  const ErrorMessage = () => {
    return (
      <>
        <p style={{ marginRight: "10px", color: "red" }}>
          Please Fill all the details
        </p>
      </>
    );
  };

  const handleAddChip = (event, newValue, sectionName) => {
    if (sectionName === "languages") {
      setFormData((prevState) => ({
        ...prevState,
        [sectionName]: newValue.join(","),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [sectionName]: newValue.join("||"),
      }));
    }
  };

  const handleBlur = (e, sectionName) => {
    const { value } = e.target;
    if (value.trim() !== "") {
      handleAddChip(
        null,
        [
          ...formData[sectionName].split("||").filter((item) => item),
          value.trim(),
        ],
        sectionName
      );
      e.target.value = ""; // Clear the input after adding chip
    }
  };

  const handleKeyDown = (e, sectionName) => {
    if (e.key === "Enter" || e.key === ",") {
      const { value } = e.target;
      if (value.trim() !== "") {
        handleAddChip(
          null,
          [
            ...formData[sectionName].split("||").filter((item) => item),
            value.trim(),
          ],
          sectionName
        );
        e.target.value = ""; // Clear the input after adding chip
        e.preventDefault(); // Prevent form submission
      }
    }
  };

  //HandleGoogleReviews

  const [reviews, setReviews] = useState({
    content: "",
    name: "",
    review_date: format(date, "dd-MM-yyyy"),
    rating: 0,
  });

  const handleReviewChange = (event, newValue) => {
    const { name, value } = event.target
      ? event.target
      : { name: "rating", value: newValue };

    // Update state and use a callback to log the updated state
    setReviews((prevValues) => ({
      ...prevValues,
      [name]: name === "rating" ? Number(value) : value,
    }));
  };

  const handleReviewSubmit = () => {
    if (editIndex !== null) {
      // If editIndex is not -1, it means we are editing an existing review
      const updatedReviews = [...formData.google_reviews];
      updatedReviews[editIndex] = reviews; // Replace the old review with the edited one
      setFormData((prevValues) => ({
        ...prevValues,
        google_reviews: updatedReviews,
      }));
      setEditIndex(null); // Reset editIndex after saving edits
    } else {
      // If editIndex is -1, it means we are adding a new review
      setFormData((prevValues) => ({
        ...prevValues,
        google_reviews: [...prevValues.google_reviews, reviews],
      }));
    }
    setReviews({
      content: "",
      name: "",
      review_date: format(new Date(), "dd-MM-yyyy"),
      rating: 0,
    });
  };

  const handleReviewEdit = (index) => {
    // Set the current reviews state to reflect the selected review for editing
    const editedReview = formData.google_reviews[index];
    setReviews({
      content: editedReview.content,
      name: editedReview.name,
      review_date: editedReview.review_date,
      rating: editedReview.rating,
    });
    setEditIndex(index); // Set the editIndex to the index of the review being edited
  };

  const handleReviewDelete = (index) => {
    // Example function to delete a review
    const updatedReviews = formData.google_reviews.filter(
      (_, i) => i !== index
    ); // Filter out the review to delete
    setFormData((prevValues) => ({
      ...prevValues,
      google_reviews: updatedReviews,
    }));
  };

  const handleSaveEdit = () => {
    handleReviewSubmit(); // Call the same function used for submitting reviews
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileInput = useRef(null);
  const handleClick = (e) => {
    handleFileInput.current.click();
  };

  const handleImageChange = async (event, profileIndex) => {
    const input = event.target;
    const files = Array.from(input.files);

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFiles = await Promise.all(
        files.map((file) => imageCompression(file, options))
      );

      const fileObjects = compressedFiles.map((file, index) => ({
        name: files[index].name,
        url: URL.createObjectURL(file),
        file,
      }));

      setSelectedImages((prevImages) => {
        const newImages = [...prevImages];

        // Limit to two images per category (profile or signature)
        if (newImages[profileIndex].length + fileObjects.length > 2) {
          alert("You can upload only 2 images.");
          return prevImages; // Return previous state without any changes
        }

        newImages[profileIndex] = [...newImages[profileIndex], ...fileObjects];
        return newImages;
      });
    } catch (error) {
      console.error(error);
    } finally {
      input.value = "";
    }
  };

  const renderPhotos = (photos, profileIndex) => {
    return photos.map((photo, index) => (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <img
          src={photo.url}
          alt={photo.name}
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            marginRight: "10px",
          }}
        />
        <p>{photo.name}</p>
        <IconButton
          onClick={() => handleDeleteImage(profileIndex, index)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>
    ));
  };

  const handleUploadImage = () => {
    const payload = {
      doc_id: "02",
    };
    const formData = {
      profile_photo: selectedImages[0]?.[0]?.file,
      signature: selectedImages[1]?.[0]?.file,
    };
    dispatch(uploadProfile({ payload, formData })).then((res) => {
      setSelectedImages([[], []]);
    });
  };

  return (
    <>
      <form onSubmit={onHandleSubmitFormata}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Background color for the parent container
            padding: "20px", // Add overall padding
          }}
        >
          <Box
            sx={{
              width: "92%",
              backgroundColor: "white",
              padding: "20px", // Add padding inside the box
              borderRadius: "8px", // Rounded corners
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow for depth
            }}
          >
            <Typography variant="h4" mb={2} fontWeight={700} textAlign="center">
              Basic Doctor Information
            </Typography>
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  name="doc_first_name"
                  value={formData.doc_first_name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Last Name */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  name="doc_last_name"
                  value={formData.doc_last_name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Age */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Age"
                  variant="outlined"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Email */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  variant="outlined"
                  name="email_id"
                  value={formData.email_id}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* <Grid item md={4} sm={12} xs={12}>
              
                <TextField
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  // required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
              {/* Mobile Number */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  name="mobile_number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Doctor Degree */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Doctor Degree"
                  variant="outlined"
                  name="doc_degree"
                  value={formData.doc_degree}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Doctor Specialization */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Doctor Specialization"
                  variant="outlined"
                  name="doc_specialization"
                  value={formData.doc_specialization}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Doctor Department */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Doctor Department"
                  variant="outlined"
                  name="doc_department"
                  value={formData.doc_department}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Doctor Working Days */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Doctor Working Days"
                  variant="outlined"
                  name="doc_working_days"
                  value={formData.doc_working_days}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Doctor Licence Number */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Doctor Licence Number"
                  variant="outlined"
                  name="doc_licence_no"
                  value={formData.doc_licence_no}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Average Consultation Time */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Average Consultation Time"
                  variant="outlined"
                  name="avg_consultation_time"
                  value={formData.avg_consultation_time}
                  onChange={handleChange}
                  required
                />
              </Grid>

              {/* Consultation Fees */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Consultation Fees"
                  variant="outlined"
                  name="consultation_fees"
                  value={formData.consultation_fees}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Follow-up Fees */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Follow-up Fees"
                  variant="outlined"
                  name="follow_up_fees"
                  value={formData.follow_up_fees}
                  onChange={handleChange}
                  required
                />
              </Grid>

              {/* Years of Experience */}
              <Grid item md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Years of Experience"
                  variant="outlined"
                  name="years_of_experience"
                  value={formData.years_of_experience}
                  onChange={handleChange}
                  required
                />
              </Grid>

              {/* Consultation Start Time */}
              <Grid item md={4} sm={12} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Consultation Start Time"
                      value={dayjs(
                        formData.consultation_start_time,
                        "HH:mm:ss"
                      )}
                      onChange={(newValue) =>
                        handleTimeChange("consultation_start_time", newValue)
                      }
                      format="HH:mm:ss"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              {/* Consultation End Time */}
              <Grid item md={4} sm={12} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Consultation End Time"
                      value={dayjs(formData.consultation_end_time, "HH:mm:ss")}
                      onChange={(newValue) =>
                        handleTimeChange("consultation_end_time", newValue)
                      }
                      format="HH:mm:ss"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              {/* Upload Photos*/}
              <Grid
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                item
                md={4}
                sm={12}
                xs={12}
              >
                <Button variant="contained" onClick={handleClickOpen}>
                  Upload Photos
                </Button>
              </Grid>
              {/* Bio */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Bio"
                  variant="outlined"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  required
                />
              </Grid>
              {/* About */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="About"
                  variant="outlined"
                  name="about"
                  value={formData.about}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  required
                />
              </Grid>
              {/* Submit Button */}
            </Grid>
            <hr />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Background color for the parent container
            padding: "20px", // Add overall padding
          }}
        >
          <Box
            sx={{
              width: "92%",
              backgroundColor: "white",
              padding: "20px", // Add padding inside the box
              borderRadius: "8px", // Rounded corners
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow for depth
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h4" mb={2} fontWeight={700}>
                Education
              </Typography>
            </Stack>

            <Grid container spacing={1} marginBottom={"10px"}>
              <Grid item xs={12} md={6}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  College
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="college"
                  value={education.college}
                  onChange={handleEducationChange}
                />
              </Grid>
              <Grid item xs={12} md={2.5}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Degree
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="degree"
                  value={education.degree}
                  onChange={handleEducationChange}
                />
              </Grid>
              <Grid item xs={12} md={2.5}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Year
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="year"
                  value={education.year}
                  onChange={handleEducationChange}
                />
              </Grid>
              <Grid
                item
                md={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton onClick={handleSubmit}>
                  {editIndex !== null ? <SaveIcon /> : <AddIcon />}
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.no</TableCell>
                    <TableCell>College</TableCell>
                    <TableCell align="right">Degree</TableCell>
                    <TableCell align="right">Year</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.education.map((row, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{idx + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.college}
                      </TableCell>
                      <TableCell align="right">{row.degree}</TableCell>
                      <TableCell align="right">{row.year}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleEditEducation(idx)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteEducation(idx)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Background color for the parent container
            padding: "20px", // Add overall padding
          }}
        >
          <Box
            sx={{
              width: "92%",
              backgroundColor: "white",
              padding: "20px", // Add padding inside the box
              borderRadius: "8px", // Rounded corners
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow for depth
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h4" mb={2} fontWeight={700}>
                External HIPs
              </Typography>
            </Stack>

            <Grid container spacing={1} marginBottom={"10px"}>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Name
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={externalHips.name}
                  onChange={handleExternalHips}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Mobile Number
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="mobile_number"
                  value={externalHips.mobile_number}
                  onChange={handleExternalHips}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Location
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="location"
                  value={externalHips.location}
                  onChange={handleExternalHips}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Address
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="address"
                  value={externalHips.address}
                  onChange={handleExternalHips}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Doctor Working Day
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="doc_working_days"
                  value={externalHips.doc_working_days}
                  onChange={handleExternalHips}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Consultation Start Time
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="consultation_start_time"
                  value={externalHips.consultation_start_time}
                  onChange={handleExternalHips}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Consultation End Time
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="consultation_end_time"
                  value={externalHips.consultation_end_time}
                  onChange={handleExternalHips}
                />
              </Grid>

              <Grid
                item
                md={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton onClick={handleSubmitHip}>
                  {editIndex !== null ? <SaveIcon /> : <AddIcon />}
                </IconButton>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.no</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right"> Mobile Number</TableCell>
                    <TableCell align="right">Location</TableCell>
                    <TableCell align="right">Address</TableCell>
                    <TableCell align="right">Doctor Working Days</TableCell>
                    <TableCell align="right">
                      {" "}
                      Consultation Start Time
                    </TableCell>
                    <TableCell align="right"> Consultation End Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.external_hips.map((row, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{idx + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.mobile_number}</TableCell>
                      <TableCell align="right">{row.location}</TableCell>
                      <TableCell align="right">{row.address}</TableCell>
                      <TableCell align="right">
                        {row.doc_working_days}
                      </TableCell>
                      <TableCell align="right">
                        {row.consultation_start_time}
                      </TableCell>
                      <TableCell align="right">
                        {row.consultation_end_time}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleEditHips(idx)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteHips(idx)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Background color for the parent container
            padding: "20px", // Add overall padding
          }}
        >
          <Box
            sx={{
              width: "92%",
              backgroundColor: "white",
              padding: "20px", // Add padding inside the box
              borderRadius: "8px", // Rounded corners
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Box shadow for depth
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h4" mb={2} fontWeight={700}>
                Others
              </Typography>
            </Stack>
            {/* Awards*/}
            <Stack direction="row" flexWrap="wrap" mb={3}>
              {formData.awards
                .split("||")
                .filter((item) => item)
                .map((option, index) => (
                  <Chip
                    sx={{ marginRight: "10px" }}
                    color="primary"
                    variant="outlined"
                    label={option}
                    key={index}
                    onDelete={() => {
                      const newChips = formData.awards
                        .split("||")
                        .filter((item, idx) => idx !== index);
                      handleAddChip(null, newChips, "awards");
                    }}
                  />
                ))}
            </Stack>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              onChange={(event, newValue) =>
                handleAddChip(event, newValue, "awards")
              }
              name="awards"
              value={formData.awards.split("||").filter((item) => item)}
              clearOnBlur={true}
              renderTags={() => null} // Do not render chips inside the TextField
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Awards"
                  placeholder="Enter the Awards"
                  onBlur={(e) => handleBlur(e, "awards")}
                  onKeyDown={(e) => handleKeyDown(e, "awards")}
                />
              )}
            />
            {/* Commonly Treat*/}
            <Stack direction="row" flexWrap="wrap" mb={3} mt={3}>
              {formData.commonly_treats
                .split("||")
                .filter((item) => item)
                .map((option, index) => (
                  <Chip
                    sx={{ marginRight: "10px" }}
                    color="success"
                    variant="outlined"
                    label={option}
                    key={index}
                    onDelete={() => {
                      const newChips = formData.commonly_treats
                        .split("||")
                        .filter((item, idx) => idx !== index);
                      handleAddChip(null, newChips, "commonly_treats");
                    }}
                  />
                ))}
            </Stack>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              onChange={(event, newValue) =>
                handleAddChip(event, newValue, "commonly_treats")
              }
              name="commonly_treats"
              value={formData.commonly_treats
                .split("||")
                .filter((item) => item)}
              clearOnBlur={true}
              renderTags={() => null} // Do not render chips inside the TextField
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Commonly Treats"
                  placeholder="Add Commonly Treats"
                  onBlur={(e) => handleBlur(e, "commonly_treats")}
                  onKeyDown={(e) => handleKeyDown(e, "commonly_treats")}
                />
              )}
            />
            {/* Education Content */}
            <Stack direction="row" flexWrap="wrap" mb={3} mt={3}>
              {formData.educational_content
                .split("||")
                .filter((item) => item)
                .map((option, index) => (
                  <Chip
                    sx={{ marginRight: "10px" }}
                    color="error"
                    variant="outlined"
                    label={option}
                    key={index}
                    onDelete={() => {
                      const newChips = formData.educational_content
                        .split("||")
                        .filter((item, idx) => idx !== index);
                      handleAddChip(null, newChips, "educational_content");
                    }}
                  />
                ))}
            </Stack>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              onChange={(event, newValue) =>
                handleAddChip(event, newValue, "educational_content")
              }
              name="educational_content"
              value={formData.educational_content
                .split("||")
                .filter((item) => item)}
              clearOnBlur={true}
              renderTags={() => null} // Do not render chips inside the TextField
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Educational Content"
                  placeholder="Enter Educational Content"
                  onBlur={(e) => handleBlur(e, "educational_content")}
                  onKeyDown={(e) => handleKeyDown(e, "educational_content")}
                />
              )}
            />
            <Stack direction="row" flexWrap="wrap" mt={4} mb={3}>
              {formData.languages
                .split(",")
                .filter((item) => item)
                .map((option, index) => (
                  <Chip
                    sx={{ marginRight: "10px" }}
                    color="secondary"
                    variant="outlined"
                    label={option}
                    key={index}
                    onDelete={() => {
                      const newLanguages = formData.languages
                        .split(",")
                        .filter((item, idx) => idx !== index);
                      handleAddChip(null, newLanguages, "languages");
                    }}
                  />
                ))}
            </Stack>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              onChange={(event, newValue) =>
                handleAddChip(event, newValue, "languages")
              }
              name="awards"
              value={formData.languages.split(",").filter((item) => item)}
              clearOnBlur={true}
              renderTags={() => null} // Do not render chips inside the TextField
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Languages"
                  placeholder="Enter the Languages"
                  onBlur={(e) => handleBlur(e, "languages")}
                  onKeyDown={(e) => handleKeyDown(e, "languages")}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f0f0f0",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              width: "92%",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h4" mb={2} fontWeight={700}>
                Reviews
              </Typography>
            </Stack>

            <Grid container spacing={2} gap={2}>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={reviews.name}
                  onChange={handleReviewChange}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  label="Content"
                  variant="outlined"
                  name="content"
                  value={reviews.content}
                  onChange={handleReviewChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                ml={2.8}
                sx={{ border: "2px solid #1976d2", borderRadius: "5px" }}
              >
                <Rating
                  name="rating"
                  value={Number(reviews.rating)} // Convert reviews.rating to a number
                  onChange={(event, newValue) =>
                    handleReviewChange(event, newValue)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="outlined"
                  sx={{ border: "2px solid" }}
                  onClick={handleReviewSubmit}
                >
                  {editIndex !== null ? "Save" : "Submit Review"}
                  {/* Change button text based on editIndex */}
                </Button>
              </Grid>
            </Grid>
            <Box>
              <Grid container>
                {formData.google_reviews.length > 0 &&
                  formData.google_reviews.map((item, index) => (
                    <Grid item xs={12} md={4}>
                      <Card
                        key={index}
                        sx={{ width: "350px", marginTop: "10px" }}
                      >
                        <CardHeader
                          avatar={
                            <Avatar
                              sx={{ bgcolor: red[500] }}
                              aria-label="recipe"
                            >
                              {item.name.charAt(0)}
                            </Avatar>
                          }
                          title={item.name}
                          subheader={item.review_date}
                        />
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            {item.content}
                          </Typography>
                          <Rating
                            name="read-only"
                            value={item.rating}
                            readOnly
                          />
                        </CardContent>
                        <CardActions disableSpacing>
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleReviewEdit(index)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Delete"
                            onClick={() => handleReviewDelete(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Stack direction="row" mt={"10px"} justifyContent="flex-end">
              {error && (
                <span style={{ color: "red" }}>
                  Please fill the required details
                </span>
              )}
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Select Images to Upload"}</DialogTitle>
        <DialogContent>
          <Typography variant="h3">
            Please upload clear images to ensure the best quality.
          </Typography>
          <hr />
          <Stack mt={2} direction="row" gap={2}>
            <Button
              sx={{
                display: "flex",
                textTransform: "none",
                border: "2px solid #1976d2",
                fontSize: "1rem",
              }}
              onClick={() => document.getElementById("profile-file").click()}
              variant="outlined"
            >
              Choose Profile Photo
            </Button>
            <input
              style={{ display: "none" }}
              type="file"
              id="profile-file"
              accept="image/*"
              name="profile"
              capture="environment"
              onChange={(e) => handleImageChange(e, 0)}
              aria-orientation="vertical"
            />
            <Button
              sx={{
                display: "flex",
                textTransform: "none",
                border: "2px solid #1976d2",
                fontSize: "1rem",
              }}
              onClick={() => document.getElementById("signature-file").click()}
              variant="outlined"
            >
              Choose Signature Photo
            </Button>
            <input
              style={{ display: "none" }}
              type="file"
              id="signature-file"
              accept="image/*"
              name="signature"
              capture="environment"
              onChange={(e) => handleImageChange(e, 1)}
              aria-orientation="vertical"
            />
          </Stack>

          <Stack direction="row" gap={2} mt={2}>
            {selectedImages.map((images, index) => (
              <div key={index}>{renderPhotos(images, index)}</div>
            ))}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
          <Button variant="contained" onClick={handleUploadImage}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DoctorProfileUpdate;
