import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddIcon from "@mui/icons-material/Add";
import {
  AppBar,
  styled,
  TextField,
  Grid,
  Autocomplete,
  Modal,
  Box,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Stack,
} from "@mui/material";
import { Delete, Assignment, ConstructionOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import { calculateBMI } from "../../utils/utils";
import {
  getFreqMedList,
  searchVitalsDetails,
} from "../../pages/DoctorPage/EMRPage/EMRPage.slice";
import { format } from "date-fns";
import RecorderComponent from "../RecorderComponent";
import { decodeHashData } from "../../utils/hashing";
import axios from "axios";
import MedicationAutoComplete from "../MedicationAutocomplete";
// import {
//   createTemplate,
//   deleteTemplate,
//   updateTemplate,
// } from "../template.slice";
// import CustomSnackbar from "../../../components/CustomSnackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  border: "1px solid #696969",
  boxShadow: 24,
  padding: "0 16px 16px",
};

const EMRFormInnerWrapper = styled("div")(({ theme }) => ({
  // height: "500px",
  // overflow: "scroll",
}));

const VitalsContainer = styled("div")(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.primaryWhite,
    marginTop: theme.spacing(4),
    padding: theme.spacing(6),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2),
    },
  },
  "& .styling_audio_details .MuiInputBase-root": {
    backgroundColor: "#1976d212",
  },
  "& .styling_template_details .MuiInputBase-root": {
    backgroundColor: "#1976d229", //#e2f6e5a8"
  },
  "& .notes-field": {
    "&.MuiFormControl-root": {
      width: "100%",
      "& > .MuiInputBase-root ": {
        minHeight: theme.spacing(43),
      },
    },
  },
  "& .textareaAutoSizeStyle textarea": {
    scrollbarWidth: "thin",
  },
}));

const TextFieldWrapper = styled("div")(({ theme }) => ({
  "&": {
    display: "flex",
    alignItems: "center",
  },
  ".emr-input-field": {
    "&.MuiFormControl-root  > .MuiInputBase-root": {
      height: "54px",
      borderRadius: "0",
    },
  },
}));

const BPTextFieldWrapper = styled("div")(({ theme }) => ({
  "&": {
    display: "flex",
    alignItems: "center",
  },
}));

const VitalValue = styled("div")(({ theme }) => ({
  "&": {
    padding: theme.spacing(3.5),
    border: `1px solid ${theme.palette.primaryGrey}`,
    textAlign: "center",
  },
}));

const FieldSpecsContainer = styled("div")(({ theme }) => ({
  "&": {
    display: "flex",
    marginTop: theme.spacing(4),
    justifyContent: "space-between",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(2),
      alignItems: "center",
      border: "1px solid #ccccccb8",
      flexWrap: "wrap",
      padding: "2px",
    },
  },
  "&.desktopTextBoxLayout": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "&.mobileTextBoxLayout": {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  "&": theme.typography.sectionBody,
  marginBottom: theme.spacing(4),
}));

const RecordLayout = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    textOverflow: "ellipsis",
  },
  [theme.breakpoints.up("md")]: {
    flex: 1,
  },
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  // textAlign: "left",
  // padding: theme.spacing(3, 4),
  // border: `1px solid ${theme.palette.primaryGrey}`,
  // flex: 1,
  // // height: theme.spacing(13),
  // borderRadius: theme.spacing(1.5),
  // height: "min-content",
  // [theme.breakpoints.down("sm")]: {
  //   height: "max-content",
  //   padding: "10px 8px",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  // "&.addMinWidth": {
  //   [theme.breakpoints.down("sm")]: {
  //     minWidth: "90px",
  //   },
  // },
}));

const TextBoxLayout = styled("div")(({ theme }) => ({
  flex: 1,
  // "& .MuiAutocomplete-root": {
  //   width: "110px",
  // },
  "&.desktopTextBoxLayout": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "&.mobileTextBoxLayout": {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    "&.frequencyInput": {
      maxWidth: "90px",
    },
  },
  "&.addMaxWidth": {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90px",
    },
  },
  "&.addMinWidth": {
    [theme.breakpoints.down("sm")]: {
      minWidth: "90px",
      ".MuiOutlinedInput-root": {
        padding: "6px",
      },
    },
  },
  ".MuiAutocomplete-input": {
    textOverflow: "clip",
  },
  "& .textareaAutoSizeStyle": {
    minWidth: "100%",
    maxWidth: "100%",
    maxHeight: "81px",
  },
}));

const RecordTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}));

const NotesWrapper = styled("div")(({ theme }) => ({
  // "&": {
  //   display: "flex",
  // },
  flex: 1,
  ".desktop": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const DeleteWrapper = styled("div")(({ theme }) => ({
  // flex: 1,
  display: "flex",
  alignItems: "center",
  ".mobile": {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const SelectedRecord = styled(Typography)(({ theme }) => ({
  "&": theme.typography.body1,
  marginBottom: theme.spacing(4),
  // marginBottom: "0",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "0",
  },
}));

const NotesField = styled(Assignment)(({ theme }) => ({
  height: "30px",
  width: "30px",
}));

const DeleteField = styled(Delete)(({ theme }) => ({
  height: "30px",
  width: "30px",
}));

const BPWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  border: "1px solid rgba(0,0,0,0.23)",
  height: "51px",
  textAlign: "center",
}));
const RowWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
}));
const DiastolicTextField = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root > .MuiInputBase-root > fieldset": {
    border: "none",
  },
}));

const SystolicTextField = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root > .MuiInputBase-root > fieldset": {
    border: "none",
  },
}));

const PrimaryButton = styled("button")(({ theme }) => ({
  "&": theme.typography.primaryButton,
  float: "right",
  [theme.breakpoints.down("sm")]: {
    padding: "5px 7px",
  },
}));

const Divider = styled("div")(({ theme }) => ({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: "bold",
}));

export default function EMRComponent({
  searchTemplate,
  setSearchTemplate,
  PMRSummarisedNotes,
  setPMRSummarisedNotes,
  emrValueRef,
  resetEMRFormRef,
  isEMR,
  isTypeAudio,
  setIsTypeAudio,
  isTemplateData,
  followUp,
  setFollowUp,
  handleDateChange,
  responseStructure,
  setResponseStructure,
  doctorId,
}) {
  const [symptomsOpts, setSymptomsOpts] = useState([]);
  const [examFindingsOpts, setExamFindingsOpts] = useState([]);
  const [diagnosisOpts, setDiagnosisOpts] = useState([]);
  const [medicationsOpts, setMedicationsOpts] = useState([]);
  const [labInvestigationsOpts, setLabInvestigationsOpts] = useState([]);
  const [vaccinationOpts, setVaccinationOpts] = useState([]); //Vaccine AutoComplete
  const [medicalHistoryOpts, setMedicalHistoryOpts] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [examFindings, setExamFinding] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  const [medications, setMedications] = useState([]);
  const [labInvestigation, setLabInvestigation] = useState([]);
  const [vaccineRecord, setVaccineRecord] = useState([]); //Vaccine Records
  const [prescriptionComment, setPrescriptionComment] = useState([]);
  const [advices, setAdvices] = useState([]);
  const pageSelected = sessionStorage.getItem("PageSelected");
  const [optionTextValues, setOptionTextValues] = useState({});
  const [symptomsSpecs, setSymptomsSpecs] = useState({});
  const [examinationSpecs, setExaminationSpecs] = useState({});
  const [diagnosisSpecs, setDiagnosisSpecs] = useState({});
  const [medicationsSpecs, setMedicationsSpecs] = useState({});
  const [labInvestigationSpecs, setLabInvestigationSpecs] = useState({});
  const [vaccineRecordSpecs, setVaccineRecordSpecs] = useState({}); //Vaccine Specs
  const [bodyMassIndex, setBodyMassIndex] = useState("");
  const medicalHistoryRef = useRef();
  const [submitEMRPayload, setSubmitEMRPayload] = useState({});
  const [step, setStep] = useState("create");
  const [number, setNumber] = useState("");
  const [symptomNumber, setSymptomNumber] = useState("");
  const [medicalHistoryNumber, setMedicalHistoryNumber] = useState("");
  const [dose, setDose] = useState("");
  const [symptomOptions, setSymptomOptions] = useState("");
  const [medicalHistoryOptions, setMedicalHistoryOptions] = useState("");
  const [diagnosisOptions, setDiagnosisOptions] = useState("");
  const [examinationFindingOptions, setExaminationFindingOptions] =
    useState("");
  const [medicationOptions, setMedicationOptions] = useState("");
  const [labInvestigationOptions, setLabInvestigationOptions] = useState("");
  const [vaccinationRecordOptions, setVaccinationRecordOptions] = useState(""); //VaccineRecordOptions
  const userRole = decodeHashData("userRole");
  const [freqMeds, setFreqMeds] = useState([]);
  const [freqMedsOpts, setFreqMedsOpts] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [medicationInputValue, setMedicationInputValue] = useState("");
  const [formValues, setFormValues] = useState({
    pulseRate: "",
    oxygenSaturation: "",
    bloodPressure: "",
    respiratoryRate: "",
    bodyTemp: "",
    bodyHeight: "",
    bodyWeight: "",
    bodyMass: "",
    bloodPressure: "",
    systolicBP: "",
    diastolicaBP: "",
    heartRate: "",
  });
  // const [followUp, setFollowUp] = useState("");
  const [treatmentPlan, setTreatmentPlan] = useState([]);
  const [templateName, setTemplateName] = useState("");

  const handleClearState = () => {
    setSymptomsOpts([]);
    setExamFindingsOpts([]);
    setDiagnosisOpts([]);
    setMedicationsOpts([]);
    setVaccinationRecordOptions([]);
    setLabInvestigationsOpts([]);
    setMedicalHistoryOpts([]);
    setSymptoms([]);
    setMedicalHistory([]);
    setVaccineRecord([]);
    setExamFinding([]);
    setDiagnosis([]);
    setMedications([]);
    setLabInvestigation([]);
    setPrescriptionComment([]);
    setAdvices([]);
    setOptionTextValues({});
    // setExistingConditionsSpecs({});
    setSymptomsSpecs({});
    setExaminationSpecs({});
    setDiagnosisSpecs({});
    setMedicationsSpecs({});
    setLabInvestigationSpecs({});
    setVaccineRecordSpecs({});
    setBodyMassIndex("");
    setSubmitEMRPayload({});
    setNumber("");
    setSymptomNumber("");
    setMedicalHistoryNumber("");
    setDose("");
    // setCleared(false);
    setSymptomOptions("");
    setMedicalHistoryOptions("");
    setDiagnosisOptions("");
    setExaminationFindingOptions("");
    setMedicationOptions("");
    setLabInvestigationOptions("");
    setFormValues({
      pulseRate: "",
      oxygenSaturation: "",
      bloodPressure: "",
      respiratoryRate: "",
      bodyTemp: "",
      bodyHeight: "",
      bodyWeight: "",
      bodyMass: "",
      bloodPressure: "",
      systolicBP: "",
      diastolicaBP: "",
      heartRate: "",
    });
    setFollowUp("");
    setTemplateName("");
    // setPMRSummarisedNotes([]);
  };

  useEffect(() => {
    dispatch(getFreqMedList(doctorId)).then((resData) => {
      if (resData?.payload?.length > 0) {
        setFreqMeds(resData?.payload);
        const customData = [];
        resData?.payload?.map((item) => {
          const customItem = {
            label: item?.medicine_name,
            value: item?.medicine_name,
          };
          customData.push(customItem);
        });
        setFreqMedsOpts(customData);
        setMedicationsOpts(customData);
      }
    });
  }, [doctorId]);

  const fetchMedicineList = () => {
    axios.get("./medicationList.json").then((response) => {
      setMedicineList(response.data);
    });
  };

  useEffect(() => {
    fetchMedicineList();
  }, []);

  const resetEMRForm = () => {
    setFormValues({
      pulseRate: "",
      oxygenSaturation: "",
      bloodPressure: "",
      respiratoryRate: "",
      bodyTemp: "",
      bodyHeight: "",
      bodyWeight: "",
      bodyMass: "",
      bloodPressure: "",
      systolicBP: "",
      diastolicaBP: "",
      heartRate: "",
      headCircumference: "",
    });
    setMedicalHistory([]);
    setSymptoms([]);
    setExamFinding([]);
    setDiagnosis([]);
    setMedications([]);
    setLabInvestigation([]);
    setOptionTextValues({});
    setExaminationSpecs({});
    setSymptomsSpecs({});
    setDiagnosisSpecs({});
    setMedicationsSpecs({});
    setLabInvestigationSpecs({});
    setPrescriptionComment([]);
    setAdvices([]);
    setTreatmentPlan([]);
  };

  useImperativeHandle(resetEMRFormRef, () => {
    return {
      resetEMRForm,
    };
  });

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (searchTemplate) {
      //vitals
      if (searchTemplate.vital) {
        const updates = {
          ...(searchTemplate.vital.pulse &&
            searchTemplate.vital.pulse !== "N/A" && {
              pulseRate: searchTemplate.vital.pulse,
            }),
          ...(searchTemplate.vital.oxygen_saturation &&
            searchTemplate.vital.oxygen_saturation !== "N/A" && {
              oxygenSaturation: searchTemplate.vital.oxygen_saturation,
            }),
          ...(searchTemplate.vital.blood_pressure &&
            searchTemplate.vital.blood_pressure !== "N/A" && {
              bloodPressure: searchTemplate.vital.blood_pressure,
            }),
          ...(searchTemplate.vital.respiratory_rate &&
            searchTemplate.vital.respiratory_rate !== "N/A" && {
              respiratoryRate: searchTemplate.vital.respiratory_rate,
            }),
          ...(searchTemplate.vital.body_temperature &&
            searchTemplate.vital.body_temperature !== "N/A" && {
              bodyTemp: searchTemplate.vital.body_temperature,
            }),
          ...(searchTemplate.vital.height &&
            searchTemplate.vital.height !== "N/A" && {
              bodyHeight: searchTemplate.vital.height,
            }),
          ...(searchTemplate.vital.weight &&
            searchTemplate.vital.weight !== "N/A" && {
              bodyWeight: searchTemplate.vital.weight,
            }),
          ...(searchTemplate.vital.body_mass_index &&
            searchTemplate.vital.body_mass_index !== "N/A" && {
              bodyMass: searchTemplate.vital.body_mass_index,
            }),
          ...(searchTemplate.vital.systolic_blood_pressure &&
            searchTemplate.vital.systolic_blood_pressure !== "N/A" && {
              systolicBP: searchTemplate.vital.systolic_blood_pressure,
            }),
          ...(searchTemplate.vital.diastolic_blood_pressure &&
            searchTemplate.vital.diastolic_blood_pressure !== "N/A" && {
              diastolicaBP: searchTemplate.vital.diastolic_blood_pressure,
            }),
          ...(searchTemplate.vital.head_circumference &&
            searchTemplate.vital.head_circumference !== "N/A" && {
              headCircumference: searchTemplate.vital.head_circumference,
            }),
          ...(searchTemplate.vital.heart_rate &&
            searchTemplate.vital.heart_rate !== "N/A" && {
              heartRate: searchTemplate.vital.heart_rate,
            }),
        };

        setFormValues((prevState) => ({
          ...prevState,
          ...updates,
        }));
      }
      //symptoms or complaints
      if (searchTemplate?.symptom?.data?.length > 0) {
        const symptomList = searchTemplate?.symptom.data;
        symptomList.map((symptomsData) => {
          if (symptomsData.symptom !== "N/A") {
            setSymptoms((symptoms) => [
              ...symptoms,
              {
                label: symptomsData.symptom,
                value: symptomsData.symptom,
                snowmed_code: symptomsData.snowmed_code,
                snowmed_display: symptomsData.snowmed_display,
                type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
              },
            ]);
            setSymptomsSpecs((prevState) => ({
              ...prevState,
              [symptomsData.symptom]: {
                since:
                  symptomsData.duration !== "N/A" ? symptomsData.duration : "",
                severity:
                  symptomsData.severity !== "N/A" ? symptomsData.severity : "",
                notes: symptomsData.notes !== "N/A" ? symptomsData.notes : "",
              },
            }));
          }
        });
      }
      //medical history
      if (searchTemplate?.medical_history?.data?.length > 0) {
        const medicalHistoryList = searchTemplate?.medical_history.data;
        medicalHistoryList.map((medicalHistoryData) => {
          if (medicalHistoryData.medical_history !== "N/A") {
            setMedicalHistory((medicalHistory) => [
              ...medicalHistory,
              {
                label: medicalHistoryData.medical_history,
                value: medicalHistoryData.medical_history,
                snowmed_code: medicalHistoryData.snowmed_code,
                snowmed_display: medicalHistoryData.snowmed_display,
                type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
              },
            ]);
            setOptionTextValues((prevState) => ({
              ...prevState,
              [medicalHistoryData.medical_history]: {
                since:
                  medicalHistoryData.since !== "N/A"
                    ? medicalHistoryData.since
                    : "",
                relationship:
                  medicalHistoryData.relationship !== "N/A"
                    ? medicalHistoryData.relationship
                    : "",
                severity:
                  medicalHistoryData.severity !== "N/A"
                    ? medicalHistoryData.severity
                    : "",
                notes:
                  medicalHistoryData.notes !== "N/A"
                    ? medicalHistoryData.notes
                    : "",
              },
            }));
          }
        });
      }
      //physical examination findings
      if (searchTemplate?.examination_findings?.data?.length > 0) {
        const examinationFindingsList =
          searchTemplate?.examination_findings.data;
        examinationFindingsList.map((findingsData) => {
          if (findingsData.disease !== "N/A") {
            setExamFinding((examFindings) => [
              ...examFindings,
              {
                label: findingsData.disease,
                value: findingsData.disease,
                snowmed_code: findingsData.snowmed_code,
                snowmed_display: findingsData.snowmed_display,
                type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
              },
            ]);
            setExaminationSpecs((prevState) => ({
              ...prevState,
              [findingsData.disease]: {
                disease:
                  findingsData.disease !== "N/A"
                    ? findingsData.disease
                    : "Examination Findings",
              },
            }));
          }
        });
      }
      //diagnosis
      if (searchTemplate?.diagnosis?.data?.length > 0) {
        const diagnosisList = searchTemplate?.diagnosis.data;
        diagnosisList.map((diagnosisData) => {
          if (diagnosisData.disease !== "N/A") {
            setDiagnosis((diagnosis) => [
              ...diagnosis,
              {
                label: diagnosisData.disease,
                value: diagnosisData.disease,
                snowmed_code: diagnosisData.snowmed_code,
                snowmed_display: diagnosisData.snowmed_display,
                type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
              },
            ]);
            setDiagnosisSpecs((prevState) => ({
              ...prevState,
              [diagnosisData.disease]: {
                since:
                  diagnosisData.status !== "N/A" ? diagnosisData.status : "",
                severity:
                  diagnosisData.diagnosis_type !== "N/A"
                    ? diagnosisData.diagnosis_type
                    : "",
                notes: diagnosisData.notes !== "N/A" ? diagnosisData.notes : "",
              },
            }));
          }
        });
      }
      //lab investigation
      if (searchTemplate?.lab_investigation?.data?.length > 0) {
        const labInvestigationList = searchTemplate?.lab_investigation.data;
        labInvestigationList.map((labInvestigationData) => {
          if (labInvestigationData.name !== "N/A") {
            setLabInvestigation((labInvestigation) => [
              ...labInvestigation,
              {
                label: labInvestigationData.name,
                value: labInvestigationData.name,
                snowmed_code: labInvestigationData.snowmed_code,
                snowmed_display: labInvestigationData.snowmed_display,
                type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
              },
            ]);
            setLabInvestigationSpecs((prevState) => ({
              ...prevState,
              [labInvestigationData.name]: {
                name:
                  labInvestigationData.name !== "N/A"
                    ? labInvestigationData.name
                    : "lab test",
              },
            }));
          }
        });
      }
      //prescription and medication
      if (searchTemplate?.medication?.data?.length > 0) {
        const medicationList = searchTemplate?.medication?.data;
        medicationList.map((medicationData) => {
          if (medicationData.medicine_name !== "N/A") {
            setMedications((medications) => [
              ...medications,
              {
                label: medicationData.medicine_name,
                value: medicationData.medicine_name,
                snowmed_code: medicationData.snowmed_code,
                snowmed_display: medicationData.snowmed_display,
                type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
              },
            ]);
            setMedicationsSpecs((prevState) => ({
              ...prevState,
              [medicationData.medicine_name]: {
                severity:
                  medicationData?.frequency !== "N/A"
                    ? medicationData?.frequency
                    : "",
                timing:
                  medicationData?.time_of_day !== "N/A"
                    ? medicationData?.time_of_day
                    : "",
                dose:
                  medicationData?.dosage !== "N/A"
                    ? medicationData?.dosage
                    : "",
                since:
                  medicationData?.duration !== "N/A"
                    ? medicationData?.duration
                    : "",
                notes:
                  medicationData?.notes !== "N/A" ? medicationData?.notes : "",
              },
            }));
          }
        });
      }

      //Advice Mapping
      let advicesData = [];
      if (searchTemplate?.advice && searchTemplate?.advice !== "N/A") {
        advicesData.push(searchTemplate?.advice);
      }
      if (
        searchTemplate?.food_intake_advice &&
        searchTemplate?.food_intake_advice !== "N/A"
      ) {
        advicesData.push(searchTemplate?.food_intake_advice);
      }
      if (
        searchTemplate?.dietary_advice &&
        searchTemplate?.dietary_advice !== "N/A"
      ) {
        advicesData.push(searchTemplate?.dietary_advice);
      }
      if (
        searchTemplate?.precautions &&
        searchTemplate?.precautions !== "N/A"
      ) {
        advicesData.push(searchTemplate?.precautions);
      }
      if (advicesData.length > 0) setAdvices([...advices, advicesData]);

      //Notes Mapping
      if (searchTemplate?.notes && searchTemplate?.notes !== "N/A") {
        let commentData = [];
        commentData.push("Notes: " + searchTemplate?.notes);
        setPrescriptionComment((prescriptionComment) => [
          ...prescriptionComment,
          commentData,
        ]);
      }

      //Set follow up date
      if (
        searchTemplate?.followup_date &&
        searchTemplate?.followup_date !== "N/A"
      ) {
        setFollowUp(searchTemplate?.followup_date);
      }
    }
  }, [searchTemplate]);

  useEffect(() => {
    // const fetchSummarizedNotes = () => {
    if (responseStructure === "SOAP") {
      if (PMRSummarisedNotes) {
        //vitals
        if (PMRSummarisedNotes?.objective?.vital_signs) {
          const vitals = PMRSummarisedNotes?.objective?.vital_signs;
          let systolic_bp;
          let diastolic_bp;
          if (vitals?.blood_pressure && /\d/.test(vitals?.blood_pressure)) {
            let bp = (vitals?.blood_pressure).match(/\d+/g);
            if (bp) {
              systolic_bp = parseInt(bp[0]);
              diastolic_bp = parseInt(bp[1]);
            }
          } else if (vitals?.blood_pressure) {
            setPrescriptionComment((prescriptionComment) => [
              ...prescriptionComment,
              `blood presure: ${vitals?.blood_pressure}`,
            ]);
          }
          const updates = {
            ...(vitals.pulse && { pulseRate: vitals.pulse }),
            ...(vitals.oxygen_saturation && {
              oxygenSaturation: vitals.oxygen_saturation,
            }),
            ...(vitals.respiratory_rate && {
              respiratoryRate: vitals.respiratory_rate,
            }),
            ...(vitals.body_temperature && {
              bodyTemp: vitals.body_temperature,
            }),
            ...(vitals.height && {
              bodyHeight: vitals.height,
            }),
            ...(vitals.weight && {
              bodyWeight: vitals.weight,
            }),
            ...(vitals.body_mass_index && {
              bodyMass: vitals.body_mass_index,
            }),
            ...(systolic_bp && {
              systolicBP: systolic_bp,
            }),
            ...(diastolic_bp && {
              diastolicaBP: diastolic_bp,
            }),
            ...(vitals.heart_rate && {
              heartRate: vitals.heart_rate,
            }),
            ...(vitals.head_circumference && {
              headCircumference: vitals.head_circumference,
            }),
          };

          setFormValues((prevState) => ({
            ...prevState,
            ...updates,
          }));
        }
        if (PMRSummarisedNotes?.follow_up_date) {
          setFollowUp(PMRSummarisedNotes?.follow_up_date);
        }
        if (
          PMRSummarisedNotes?.subjective?.history_of_present_illness.length > 0
        ) {
          const illnessList =
            PMRSummarisedNotes?.subjective?.history_of_present_illness;
          illnessList.map((illness) => {
            if (illness.trim() !== "") {
              setSymptoms((symptoms) => [
                ...symptoms,
                {
                  label: illness,
                  value: illness,
                  // snowmed_code: symptomsData.snowmed_code,
                  // snowmed_display: symptomsData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setSymptomsSpecs((prevState) => ({
                ...prevState,
                [illness]: {
                  since: "",
                  severity: "",
                  notes: "",
                  tag: "present illness history",
                },
              }));
            }
          });
        }

        //medical history
        if (PMRSummarisedNotes?.subjective?.past_medical_history?.length > 0) {
          const medicalHistoryList =
            PMRSummarisedNotes?.subjective?.past_medical_history;
          medicalHistoryList.map((medicalHistoryData) => {
            if (medicalHistoryData.trim() !== "") {
              setMedicalHistory((medicalHistory) => [
                ...medicalHistory,
                {
                  label: "p.h - " + medicalHistoryData,
                  value: medicalHistoryData,
                  // snowmed_code: medicalHistoryData.snowmed_code,
                  // snowmed_display: medicalHistoryData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setOptionTextValues((prevState) => ({
                ...prevState,
                [medicalHistoryData]: {
                  since: "",
                  relationship: "",
                  severity: "",
                  notes: "",
                  tag: "p.h",
                },
              }));
            }
          });
        }
        if (PMRSummarisedNotes?.subjective?.family_history?.length > 0) {
          const medicalHistoryList =
            PMRSummarisedNotes?.subjective?.family_history;
          medicalHistoryList.map((medicalHistoryData) => {
            if (medicalHistoryData.trim() !== "") {
              setMedicalHistory((medicalHistory) => [
                ...medicalHistory,
                {
                  label: "f.h - " + medicalHistoryData,
                  value: medicalHistoryData,
                  // snowmed_code: medicalHistoryData.snowmed_code,
                  // snowmed_display: medicalHistoryData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setOptionTextValues((prevState) => ({
                ...prevState,
                [medicalHistoryData]: {
                  since: "",
                  relationship: "",
                  severity: "",
                  notes: "",
                  tag: "f.h",
                },
              }));
            }
          });
        }

        //physical examination findings
        if (
          PMRSummarisedNotes?.objective?.physical_examination_findings.length >
          0
        ) {
          const examinationFindingsList =
            PMRSummarisedNotes?.objective?.physical_examination_findings;
          // examinationFindingsList.map((findingsData) => {
          setExamFinding((examFindings) => [
            ...examFindings,
            {
              label: examinationFindingsList,
              value: examinationFindingsList,
              // snowmed_code: findingsData.snowmed_code,
              // snowmed_display: findingsData.snowmed_display,
              type: isTypeAudio ? "audio" : isTemplateData ? "template" : "",
            },
          ]);
          if (examinationFindingsList.trim() !== "") {
            setExaminationSpecs((prevState) => ({
              ...prevState,
              [examinationFindingsList]: {
                notes: "",
              },
            }));
          }
          // });
        }

        //diagnosis
        if (PMRSummarisedNotes?.assessment) {
          const diagnosisList =
            PMRSummarisedNotes?.assessment?.preliminary_diagnosis.split("|");
          diagnosisList.map((diagnosisData) => {
            if (diagnosisData.trim() !== "") {
              setDiagnosis((diagnosis) => [
                ...diagnosis,
                {
                  label: diagnosisData,
                  value: diagnosisData,
                  // snowmed_code: diagnosisData.snowmed_code,
                  // snowmed_display: diagnosisData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setDiagnosisSpecs((prevState) => ({
                ...prevState,
                [diagnosisData]: {
                  since: "",
                  severity: "",
                  notes: "",
                  tag: "preliminary",
                },
              }));
            }
          });

          const differentialDiagnosis =
            PMRSummarisedNotes?.assessment?.differential_diagnosis.split("|");
          differentialDiagnosis.map((diagnosisData) => {
            if (diagnosisData.trim() !== "") {
              setDiagnosis((diagnosis) => [
                ...diagnosis,
                {
                  label: diagnosisData,
                  value: diagnosisData,
                  // snowmed_code: diagnosisData.snowmed_code,
                  // snowmed_display: diagnosisData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setDiagnosisSpecs((prevState) => ({
                ...prevState,
                [diagnosisData]: {
                  since: "",
                  severity: "",
                  notes: "",
                  tag: "differential",
                },
              }));
            }
          });
        }

        //lab investigation
        if (
          PMRSummarisedNotes?.tests_to_be_taken?.laboratory_tests.length > 0
        ) {
          const labInvestigationList =
            PMRSummarisedNotes?.tests_to_be_taken?.laboratory_tests;
          labInvestigationList.map((labInvestigationData) => {
            if (labInvestigationData.trim() !== "") {
              setLabInvestigation((labInvestigation) => [
                ...labInvestigation,
                {
                  label: labInvestigationData,
                  value: labInvestigationData,
                  // snowmed_code: labInvestigationData.snowmed_code,
                  // snowmed_display: labInvestigationData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setLabInvestigationSpecs((prevState) => ({
                ...prevState,
                [labInvestigationData]: {
                  notes: "lab test",
                },
              }));
            }
          });
        }
        if (PMRSummarisedNotes?.tests_to_be_taken?.imaging_tests.length > 0) {
          const labInvestigationList =
            PMRSummarisedNotes?.tests_to_be_taken?.imaging_tests;
          labInvestigationList.map((labInvestigationData) => {
            if (labInvestigationData.trim() !== "") {
              setLabInvestigation((labInvestigation) => [
                ...labInvestigation,
                {
                  label: labInvestigationData,
                  value: labInvestigationData,
                  // snowmed_code: labInvestigationData.snowmed_code,
                  // snowmed_display: labInvestigationData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setLabInvestigationSpecs((prevState) => ({
                ...prevState,
                [labInvestigationData]: {
                  notes: "imaging test",
                },
              }));
            }
          });
        }

        //prescription and medication
        if (PMRSummarisedNotes?.prescription?.medications.length > 0) {
          const medicationList = PMRSummarisedNotes?.prescription?.medications;
          medicationList.map((medicationData) => {
            if (medicationData.medicine_name !== "") {
              setMedications((medications) => [
                ...medications,
                {
                  label: medicationData?.medicine_name,
                  value: medicationData?.medicine_name,
                  // snowmed_code: medicationData.snowmed_code,
                  // snowmed_display: medicationData.snowmed_display,
                  type: isTypeAudio
                    ? "audio"
                    : isTemplateData
                    ? "template"
                    : "",
                },
              ]);
              setMedicationsSpecs((prevState) => ({
                ...prevState,
                [medicationData?.medicine_name]: {
                  severity: "",
                  timing: medicationData?.time_of_day,
                  dose: medicationData?.dosages,
                  since: medicationData?.duration,
                  notes:
                    (medicationData?.frequency
                      ? `Frequency: ${medicationData?.frequency}; `
                      : "") +
                    // (medicationData?.reason
                    //   ? `Reason: ${medicationData?.reason}; `
                    //   : "") +
                    (medicationData?.instructions
                      ? `Instructions: ${medicationData?.instructions}`
                      : ""),
                },
              }));
            }
          });
        }

        //Notes section mapping
        if (PMRSummarisedNotes?.subjective) {
          let commentData = [];
          if (
            PMRSummarisedNotes?.subjective?.medication_history.length > 0 &&
            PMRSummarisedNotes?.subjective?.medication_history[0]
          ) {
            commentData.push(
              PMRSummarisedNotes?.subjective?.medication_history
            );
          }
          if (
            PMRSummarisedNotes?.subjective?.allergy_information.length > 0 &&
            PMRSummarisedNotes?.subjective?.allergy_information[0]
          ) {
            commentData.push(
              PMRSummarisedNotes?.subjective?.allergy_information
            );
          }
          if (
            PMRSummarisedNotes?.subjective?.social_history.length > 0 &&
            PMRSummarisedNotes?.subjective?.social_history[0]
          ) {
            commentData.push(PMRSummarisedNotes?.subjective?.social_history);
          }
          if (
            PMRSummarisedNotes?.subjective?.review_of_systems.length > 0 &&
            PMRSummarisedNotes?.subjective?.review_of_systems[0]
          ) {
            commentData.push(PMRSummarisedNotes?.subjective?.review_of_systems);
          }
          if (PMRSummarisedNotes?.assessment?.risk_factors) {
            commentData.push(PMRSummarisedNotes?.assessment?.risk_factors);
          }
          if (PMRSummarisedNotes?.additional_notes?.content) {
            commentData.push(PMRSummarisedNotes?.additional_notes?.content);
          }
          setPrescriptionComment((prescriptionComment) => [
            ...prescriptionComment,
            commentData,
          ]);

          let advicesData = [];
          if (
            PMRSummarisedNotes?.other_next_steps?.consultations.length > 0 &&
            PMRSummarisedNotes?.other_next_steps?.consultations[0]
          ) {
            advicesData.push(
              "Consultations: " +
                PMRSummarisedNotes?.other_next_steps?.consultations
            );
          }
          if (
            PMRSummarisedNotes?.other_next_steps?.referrals.length > 0 &&
            PMRSummarisedNotes?.other_next_steps?.referrals[0]
          ) {
            advicesData.push(PMRSummarisedNotes?.other_next_steps?.referrals);
          }
          if (
            PMRSummarisedNotes?.other_next_steps?.precautions.length > 0 &&
            PMRSummarisedNotes?.other_next_steps?.precautions[0]
          ) {
            advicesData.push(PMRSummarisedNotes?.other_next_steps?.precautions);
          }
          if (
            PMRSummarisedNotes?.other_next_steps?.lifestyle_modifications
              .length > 0 &&
            PMRSummarisedNotes?.other_next_steps?.lifestyle_modifications[0]
          ) {
            advicesData.push(
              PMRSummarisedNotes?.other_next_steps?.lifestyle_modifications
            );
          }
          setAdvices([...advices, advicesData]);
        }

        //Treatment Plan Section
        if (
          PMRSummarisedNotes?.plan?.diagnostic_plan ||
          PMRSummarisedNotes?.plan?.treatment_plan
        ) {
          let plans = [];
          if (PMRSummarisedNotes?.plan?.diagnostic_plan) {
            // plans.push("Lifestyle Modifications: " + PMRSummarisedNotes?.other_next_steps?.lifestyle_modifications);
            plans.push(
              "Diagnostic Plan: " + PMRSummarisedNotes?.plan?.diagnostic_plan
            );
          }
          if (PMRSummarisedNotes?.plan?.treatment_plan)
            plans.push(
              treatmentPlan +
                "Treatment Plan: " +
                PMRSummarisedNotes?.plan?.treatment_plan
            );

          setTreatmentPlan(plans);
        }
        if (PMRSummarisedNotes?.plan?.patient_education)
          setAdvices([
            ...advices,
            "Patient Education: " + PMRSummarisedNotes?.plan?.patient_education,
          ]);
      }
    } else {
      setSearchTemplate(PMRSummarisedNotes);
    }
  }, [PMRSummarisedNotes]);

  // fetching details from snomwed
  useEffect(() => {
    if (symptomOptions.length >= 2) {
      // Call your API here and fetch data based on the inputValue
      const queryParams = {
        term: symptomOptions,
        state: "active",
        semantictag: "finding",
        acceptability: "all",
        groupbyconcept: "true",
        returnlimit: 15,
      };

      dispatch(searchVitalsDetails(queryParams)).then((res) => {
        const customData = [];
        const resData = res.payload?.data;
        if (resData?.length > 0) {
          resData?.map((item) => {
            const customItem = {
              label: item?.term,
              value: item?.term,
              snowmed_code: item?.conceptId,
              snowmed_display: item?.conceptFsn,
            };
            customData.push(customItem);
          });
          setSymptomsOpts(customData);
        } else {
          const customItem = {
            label: symptomOptions,
            value: symptomOptions,
            snowmed_code: "",
            snowmed_display: "",
          };
          customData.push(customItem);
          setSymptomsOpts(customData);
        }
      });
    } else {
      setSymptomsOpts([]);
    }
  }, [symptomOptions]);

  useEffect(() => {
    if (medicalHistoryOptions.length >= 2) {
      const queryParams = {
        term: medicalHistoryOptions,
        state: "active",
        semantictag: "situation",
        acceptability: "all",
        returnlimit: 15,
      };
      dispatch(searchVitalsDetails(queryParams)).then((res) => {
        const customData = [];
        const resData = res.payload?.data;
        if (resData?.length > 0) {
          resData?.map((item) => {
            const customItem = {
              label: item?.term,
              value: item?.term,
              snowmed_code: item?.conceptId,
              snowmed_display: item?.conceptFsn,
            };
            customData.push(customItem);
          });
          setMedicalHistoryOpts(customData);
        } else {
          const customItem = {
            label: medicalHistoryOptions,
            value: medicalHistoryOptions,
            snowmed_code: "",
            snowmed_display: "",
          };
          customData.push(customItem);
          setMedicalHistoryOpts(customData);
        }
      });
    } else {
      setMedicalHistoryOpts([]);
    }
  }, [medicalHistoryOptions]);

  useEffect(() => {
    if (examinationFindingOptions.length >= 2) {
      const queryParams = {
        term: examinationFindingOptions,
        state: "active",
        semantictag: "finding++observable entity++morphologic abnormality",
        acceptability: "all",
        groupbyconcept: "true",
        returnlimit: 15,
      };

      dispatch(searchVitalsDetails(queryParams)).then((res) => {
        const customData = [];
        const resData = res.payload?.data;
        if (resData?.length > 0) {
          resData?.map((item) => {
            const customItem = {
              label: item?.term,
              value: item?.term,
              snowmed_code: item?.conceptId,
              snowmed_display: item?.conceptFsn,
            };
            customData.push(customItem);
          });
          setExamFindingsOpts(customData);
        } else {
          const customItem = {
            label: examinationFindingOptions,
            value: examinationFindingOptions,
            snowmed_code: "",
            snowmed_display: "",
          };
          customData.push(customItem);
          setExamFindingsOpts(customData);
        }
      });
    } else {
      setExamFindingsOpts([]);
    }
  }, [examinationFindingOptions]);

  useEffect(() => {
    if (diagnosisOptions.length >= 2) {
      const queryParams = {
        term: diagnosisOptions,
        state: "active",
        semantictag: "disorder",
        acceptability: "all",
        groupbyconcept: "true",
        returnlimit: 15,
      };

      dispatch(searchVitalsDetails(queryParams)).then((res) => {
        const customData = [];
        const resData = res.payload?.data;
        if (resData?.length > 0) {
          resData?.map((item) => {
            const customItem = {
              label: item?.term,
              value: item?.term,
              snowmed_code: item?.conceptId,
              snowmed_display: item?.conceptFsn,
            };
            customData.push(customItem);
          });
          setDiagnosisOpts(customData);
        } else {
          const customItem = {
            label: diagnosisOptions,
            value: diagnosisOptions,
            snowmed_code: "",
            snowmed_display: "",
          };
          customData.push(customItem);
          setDiagnosisOpts(customData);
        }
      });
    } else {
      setDiagnosisOpts([]);
    }
  }, [diagnosisOptions]);

  useEffect(() => {
    if (labInvestigationOptions.length >= 2) {
      const queryParams = {
        term: labInvestigationOptions,
        state: "active",
        semantictag: "procedure",
        acceptability: "all",
        groupbyconcept: "true",
        returnlimit: 15,
      };

      dispatch(searchVitalsDetails(queryParams)).then((res) => {
        const customData = [];
        const resData = res.payload?.data;
        if (resData?.length > 0) {
          resData?.map((item) => {
            const customItem = {
              label: item?.term,
              value: item?.term,
              snowmed_code: item?.conceptId,
              snowmed_display: item?.conceptFsn,
            };
            customData.push(customItem);
          });
          setLabInvestigationsOpts(customData);
        } else {
          const customItem = {
            label: labInvestigationOptions,
            value: labInvestigationOptions,
            snowmed_code: "",
            snowmed_display: "",
          };
          customData.push(customItem);
          setLabInvestigationsOpts(customData);
        }
      });
    } else {
      setLabInvestigationsOpts([]);
    }
  }, [labInvestigationOptions]);

  useEffect(() => {
    if (medicationOptions.length >= 2) {
      //   const queryParams = {
      //     term: medicationOptions,
      //     state: "active",
      //     semantictag: "real clinical drug++substance++product name",
      //     acceptability: "all",
      //     groupbyconcept: "true",
      //     returnlimit: 15,
      //   };

      //   dispatch(searchVitalsDetails(queryParams)).then((res) => {
      const customData = [];
      const resData = medicineList.filter((item) =>
        item?.SKU.toLowerCase().startsWith(medicationOptions.toLowerCase())
      );
      if (resData?.length > 0) {
        resData?.map((item) => {
          const customItem = {
            label: item?.SKU,
            value: item?.SKU,
            // snowmed_code: item?.conceptId,
            // snowmed_display: item?.conceptFsn,
          };
          customData.push(customItem);
        });
        setMedicationsOpts(customData);
      } else {
        const customItem = {
          label: medicationOptions,
          value: medicationOptions,
          snowmed_code: "",
          snowmed_display: "",
        };
        customData.push(customItem);
        setMedicationsOpts(customData);
      }
      // });
    } else {
      setMedicationsOpts([]);
    }
  }, [medicationOptions]);

  // Option change
  const handleMeidcalHistoryChange = async (event) => {
    setTimeout(() => {
      setMedicalHistoryOptions(event.target.value);
    }, 1000);
  };
  const handleSymptompsChange = async (event) => {
    if (event.target.value.trim() !== "") {
      setTimeout(() => {
        setSymptomOptions(event.target.value);
      }, 1000);
    }
  };
  const handleExamFindingsChange = async (event) => {
    if (event.target.value.trim() !== "") {
      setTimeout(() => {
        setExaminationFindingOptions(event.target.value);
      }, 1000);
    }
  };
  const handleDiagnosisChange = async (event) => {
    if (event.target.value.trim() !== "") {
      setTimeout(() => {
        setDiagnosisOptions(event.target.value);
      }, 1000);
    }
  };
  const handleMedicationsChange = async (event) => {
    setMedicationInputValue(event.target.value);
    if (event.target.value.trim() !== "") {
      setTimeout(() => {
        setMedicationOptions(event.target.value);
      }, 1000);
    }
  };
  const handleLabInvestigationsChange = async (event) => {
    if (event.target.value.trim() !== "") {
      setTimeout(() => {
        setLabInvestigationOptions(event.target.value);
      }, 1000);
    }
  };

  //VACCINATION RECORD INPUT
  const handleVaccinationRecordChange = async (event) => {
    if (event.target.value !== "") {
      setTimeout(() => {
        setVaccinationRecordOptions(event.target.value);
      }, 1000);
    }
  };

  // const clearVaccinationRecordOptions = (event) => {
  //   setVaccinationOpts();
  // };

  useEffect(() => {
    const vaccineData = [
      { label: "BCG", value: "BCG" },
      { label: "Hepatitis B", value: "Hepatitis B" },
      { label: "DTP", value: "DTP" },
      { label: "Polio", value: "Polio" },
      { label: "Hib", value: "Hib" },
      { label: "Rotavirus", value: "Rotavirus" },
      { label: "Pneumococcal", value: "Pneumococcal" },
      { label: "Measles", value: "Measles" },
      { label: "Mumps", value: "Mumps" },
      { label: "Rubella", value: "Rubella" },
      { label: "Hepatitis A", value: "Hepatitis A" },
      { label: "Varicella", value: "Varicella" },
      { label: "Influenza", value: "Influenza" },
      { label: "Tdap", value: "Tdap" },
      { label: "HPV", value: "HPV" },
      { label: "Meningococcal", value: "Meningococcal" },
      { label: "COVID-19", value: "COVID-19" },
    ];
    setVaccinationOpts(vaccineData);
  }, []);

  const handleVaccineRecords = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      const fieldValue = value;

      setVaccineRecordSpecs((prevSpecs) => ({
        ...prevSpecs,
        [value?.label || value]: {
          lot_number: "",
        },
      }));

      if (value?.label) {
        setVaccineRecord((prevVaccineRecord) => [
          ...prevVaccineRecord,
          fieldValue,
        ]);
      } else {
        setVaccineRecord((prevVaccineRecord) => [
          ...prevVaccineRecord,
          {
            label: fieldValue,
            value: fieldValue,
          },
        ]);
      }
    }
  };

  const handleVaccineRecordNameChange = (event, prevValue) => {
    let newValue = event.target.value;
    if (newValue === "") {
      newValue = " ";
    }
    const updatedVaccineRecord = vaccineRecord.map((vaccine) => {
      if (vaccine.label === prevValue) {
        return { label: newValue, value: newValue };
      }
      return vaccine;
    });
    setVaccineRecord(updatedVaccineRecord);

    let details;
    Object.entries(vaccineRecordSpecs).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedVaccineSpecs = {
      ...vaccineRecordSpecs,
      [newValue]: details,
    };
    setVaccineRecordSpecs((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } = updatedVaccineSpecs;
      return restOptionTextValues;
    });
  };

  const handleVaccineRecordTextChange = (option, textField, newValue) => {
    setVaccineRecordSpecs({
      ...vaccineRecordSpecs,
      [option?.label]: {
        ...vaccineRecordSpecs[option?.label],
        [textField]: newValue,
      },
    });
  };

  const handleVaccineSpecsDelete = (optionToRemove) => () => {
    setVaccineRecord(
      vaccineRecord.filter((option) => option?.label !== optionToRemove)
    );
    setVaccineRecordSpecs((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const vaccinationRecordObj = (inputObject) => {
    const result = [];
    console.log(inputObject);

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }

      const objectDetails = inputObject[key];
      const transformedItem = {
        vaccination_name: key,
        vaccination_metadata: {
          lot_number: objectDetails.lot_number,
        },
      };

      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.vaccination_name !== "[object Object]"
    );
    return filteredResult;
  };

  const clearMedicalHistoryOptions = (event) => {
    setMedicalHistoryOpts([]);
  };
  const handleMedicalHistoryValue = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      // setShowMedicalHistory(true);
      const fieldValue = value;
      setOptionTextValues({
        ...optionTextValues,
        [value?.label || value]: {
          since: "",
          relationship: "",
          severity: "",
          notes: "",
          tag: "new",
        },
      });
      if (value?.label) {
        setMedicalHistory([...medicalHistory, fieldValue]);
      } else {
        setMedicalHistory((medicalHistory) => [
          ...medicalHistory,
          {
            label: fieldValue,
            value: fieldValue,
            snowmed_code: "",
            snowmed_display: "",
          },
        ]);
      }
      setMedicalHistoryOpts([]);
    }
  };

  const clearSymptomsOptions = (event) => {
    setSymptomsOpts([]);
  };
  const handleSymptoms = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      const fieldValue = value;
      setSymptomsSpecs({
        ...symptomsSpecs,
        [value?.label || value]: {
          since: "",
          severity: "",
          notes: "",
          tag: "new",
        },
      });
      if (value?.label) {
        setSymptoms([...symptoms, fieldValue]);
      } else {
        setSymptoms((symptoms) => [
          ...symptoms,
          {
            label: fieldValue,
            value: fieldValue,
            snowmed_code: "",
            snowmed_display: "",
          },
        ]);
      }
      setSymptomsOpts([]);
    }
  };

  const clearExaminationFindingOptions = (event) => {
    setExamFindingsOpts([]);
  };
  const handleExaminationFindings = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      const fieldValue = value;

      setExaminationSpecs({
        ...examinationSpecs,
        [value?.label || value]: { notes: "" },
      });

      if (value?.label) {
        setExamFinding([...examFindings, fieldValue]);
      } else {
        setExamFinding((examFindings) => [
          ...examFindings,
          {
            label: fieldValue,
            value: fieldValue,
            snowmed_code: "",
            snowmed_display: "",
          },
        ]);
      }
      // handleExaminationTextChange(value, "notes", "");
      setExamFindingsOpts([]);
    }
  };

  const clearDiagnosisOptions = (event) => {
    setDiagnosisOpts([]);
  };
  const handleDiagnosis = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      const fieldValue = value;
      setDiagnosisSpecs({
        ...diagnosisSpecs,
        [value?.label || value]: {
          since: "",
          severity: "",
          notes: "",
          tag: "new",
        },
      });

      if (value?.label) {
        setDiagnosis([...diagnosis, fieldValue]);
      } else {
        setDiagnosis((diagnosis) => [
          ...diagnosis,
          {
            label: fieldValue,
            value: fieldValue,
            snowmed_code: "",
            snowmed_display: "",
          },
        ]);
      }
      setDiagnosisOpts([]);
    }
  };

  const clearMedicationOptions = (event) => {
    setMedicationsOpts(freqMedsOpts);
  };
  const handleMedications = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      const fieldValue = value;
      const data = freqMeds.filter(
        (item) => item?.medicine_name === (value?.label || value)
      );
      if (data.length > 0) {
        setMedicationsSpecs({
          ...medicationsSpecs,
          [data[0].medicine_name]: {
            severity: data[0]?.frequency,
            time: data[0]?.time_of_day,
            dose: data[0]?.dosage,
            since: data[0].duration,
            notes: data[0]?.notes,
          },
        });
      } else {
        setMedicationsSpecs({
          ...medicationsSpecs,
          [value?.label || value]: { since: "", severity: "", notes: "" },
        });
      }

      if (value?.label) {
        setMedications([...medications, fieldValue]);
      } else {
        setMedications((medications) => [
          ...medications,
          {
            label: fieldValue,
            value: fieldValue,
            snowmed_code: "",
            snowmed_display: "",
          },
        ]);
      }
      setMedicationsOpts(freqMedsOpts);
      setMedicationInputValue("");
    }
  };

  const clearLabInvestigationOptions = (event) => {
    setLabInvestigationsOpts([]);
  };

  const handleLabInvestigations = (event, value) => {
    if (value?.label || value?.trim() !== "") {
      const fieldValue = value;
      setLabInvestigationSpecs({
        ...labInvestigationSpecs,
        [value?.label || value]: { since: "", severity: "", notes: "lab test" },
      });

      if (value?.label) {
        setLabInvestigation([...labInvestigation, fieldValue]);
      } else {
        setLabInvestigation((labInvestigation) => [
          ...labInvestigation,
          {
            label: fieldValue,
            value: fieldValue,
            snowmed_code: "",
            snowmed_display: "",
          },
        ]);
      }
      // handleLabTextChange(value, "notes", "");
      setLabInvestigationsOpts([]);
    }
  };

  const treatmentPlanChange = (event) => {
    setTreatmentPlan([event.target.value]);
  };

  const prescriptionCommentChange = (event) => {
    setPrescriptionComment([event.target.value]);
  };

  const adviceChange = (event) => {
    setAdvices([event.target.value]);
  };

  const handleTextFieldChange = (option, textField, newValue) => {
    setOptionTextValues({
      ...optionTextValues,
      [option?.label]: {
        ...optionTextValues[option?.label],
        [textField]: newValue,
        snowmed_code: option?.snowmed_code,
        snowmed_display: option?.snowmed_display,
      },
    });
  };

  const handleOptionRemove = (optionToRemove) => () => {
    setMedicalHistory(
      medicalHistory.filter((option) => option?.label !== optionToRemove)
    );
    setOptionTextValues((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const handleSymtomsTextChange = (option, textField, newValue) => {
    setSymptomsSpecs({
      ...symptomsSpecs,
      [option?.label]: {
        ...symptomsSpecs[option?.label],
        [textField]: newValue,
        snowmed_code: option?.snowmed_code,
        snowmed_display: option?.snowmed_display,
      },
    });
  };

  const handleSymptomsSpecsDelete = (optionToRemove) => () => {
    setSymptoms(symptoms.filter((option) => option?.label !== optionToRemove));
    setSymptomsSpecs((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const handleExaminationTextChange = (option, textField, newValue) => {
    setExaminationSpecs({
      ...examinationSpecs,
      [option?.label]: {
        ...examinationSpecs[option?.label],
        [textField]: newValue,
        snowmed_code: option?.snowmed_code,
        snowmed_display: option?.snowmed_display,
      },
    });
  };
  const handleExaminationSpecsDelete = (optionToRemove) => () => {
    setExamFinding(
      examFindings.filter((option) => option?.label !== optionToRemove)
    );
    setExaminationSpecs((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const handleDiagnosisTextChange = (option, textField, newValue) => {
    setDiagnosisSpecs({
      ...diagnosisSpecs,
      [option?.label]: {
        ...diagnosisSpecs[option?.label],
        [textField]: newValue,
        snowmed_code: option?.snowmed_code,
        snowmed_display: option?.snowmed_display,
      },
    });
  };
  const handleDiagnosisSpecsDelete = (optionToRemove) => () => {
    setDiagnosis(
      diagnosis.filter((option) => option?.label !== optionToRemove)
    );
    setDiagnosisSpecs((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const handleMedicationsTextChange = (option, textField, newValue) => {
    let inputValue;
    if (textField === "severity") {
      const severityValue = newValue.trim().replace(/[^0-9]/g, "");
      if (severityValue.length < 3) {
        inputValue = severityValue.replace(/(\d{1})(\d{1})/, "$1-$2");
      } else if (severityValue.length < 6) {
        inputValue = severityValue.replace(/(\d{1})(\d{1})(\d{1})/, "$1-$2-$3");
      } else inputValue = "";
    } else inputValue = newValue;
    setMedicationsSpecs({
      ...medicationsSpecs,
      [option?.label]: {
        ...medicationsSpecs[option?.label],
        [textField]: inputValue,
        snowmed_code: option?.snowmed_code,
        snowmed_display: option?.snowmed_display,
      },
    });
  };
  const handleMedicationsSpecsDelete = (optionToRemove) => () => {
    setMedications(
      medications.filter((option) => option?.label !== optionToRemove)
    );
    setMedicationsSpecs((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const handleLabTextChange = (option, textField, newValue) => {
    setLabInvestigationSpecs({
      ...labInvestigationSpecs,
      [option?.label]: {
        ...labInvestigationSpecs[option?.label],
        [textField]: newValue,
        snowmed_code: option?.snowmed_code,
        snowmed_display: option?.snowmed_display,
      },
    });
  };
  const handleLabSpecsDelete = (optionToRemove) => () => {
    setLabInvestigation(
      labInvestigation.filter((option) => option?.label !== optionToRemove)
    );
    setLabInvestigationSpecs((prevState) => {
      const { [optionToRemove]: _, ...restOptionTextValues } = prevState;
      return restOptionTextValues;
    });
  };

  const diagnosisObj = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }
      const objectDetails = inputObject[key];
      const transformedItem = {
        disease: key,
        status: objectDetails.since,
        diagnosis_type: objectDetails.severity,
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
        // tag: objectDetails?.tag
      };

      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.disease !== "[object Object]"
    );
    return filteredResult;
  };

  const diseaseObject = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }
      const objectDetails = inputObject[key];
      const transformedItem = {
        disease: key,
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
      };

      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.disease !== "[object Object]"
    );
    return filteredResult;
  };
  const conditonObject = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }

      const objectDetails = inputObject[key];
      const transformedItem = {
        condition: key,
        status: objectDetails.severity,
        start_date: "2023/08/08",
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
      };

      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.condition !== "[object Object]"
    );
    return filteredResult;
  };

  const symptomObj = (inputObject) => {
    const result = [];
    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }
      if (key === "array") {
        continue;
      }

      const objectDetails = inputObject[key];
      result.push({
        symptom: key,
        duration: objectDetails.since,
        start_date: "2023/08/08",
        severity: objectDetails.severity,
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
        // tag: objectDetails?.tag
      });
    }
    const filteredResult = result.filter(
      (item) => item.symptom !== "[object Object]"
    );
    return filteredResult;
  };
  const medicationObj = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }

      const objectDetails = inputObject[key];

      const transformedItem = {
        medicine_name: key,
        frequency: objectDetails.severity,
        dosage: objectDetails.dose,
        time_of_day: objectDetails.timing,
        duration: objectDetails.since,
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
      };

      result.push(transformedItem);
    }

    const filteredResult = result.filter(
      (item) => item.medicine_name !== "[object Object]"
    );
    return filteredResult;
  };

  const currentMedicationObj = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }
      const objectDetails = inputObject[key];

      const transformedItem = {
        medicine_name: key,
        start_date: "2023/08/08",
        status: objectDetails.severity,
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
      };

      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.medicine_name !== "[object Object]"
    );
    return filteredResult;
  };

  const labInvestigationObj = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];
      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }

      const objectDetails = inputObject[key];

      const transformedItem = {
        name: key,
        notes: objectDetails?.notes,
        snowmed_code: objectDetails?.snowmed_code,
        snowmed_display: objectDetails?.snowmed_display,
      };

      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.name !== "[object Object]"
    );
    return filteredResult;
  };

  const medicalHistoryObj = (inputObject) => {
    const result = [];

    for (const key in inputObject) {
      const value = inputObject[key];

      if (
        JSON.stringify(value) === JSON.stringify({}) ||
        JSON.stringify(value) === "[object Object]" ||
        value === undefined
      ) {
        continue;
      }

      if (key === "array") {
        continue;
      }
      const objectDetails = inputObject[key];
      const transformedItem = {
        medical_history: key,
        relationship: objectDetails.relationship,
        since: objectDetails.since,
        notes: objectDetails.notes,
        snowmed_code: objectDetails?.snowmed_code || "",
        snowmed_display: objectDetails?.snowmed_display || "",
        // tag: objectDetails?.tag
      };
      result.push(transformedItem);
    }
    const filteredResult = result.filter(
      (item) => item.medical_history !== "[object Object]"
    );
    return filteredResult;
  };

  const createPayload = (key, valueArr) => {
    const payload = submitEMRPayload;
    if (key !== "vital") {
      const payloadData = {
        data: valueArr,
      };
      payload[key] = payloadData;
    }
    if (key === "vital") {
      payload[key] = valueArr;
    }
    setSubmitEMRPayload(payload);
  };

  const relationshipOptions = [
    "self",
    "father",
    "mother",
    "sister",
    "daughter",
  ];
  const diagnosisStatusOpts = ["Suspected", "Confirmed", "Ruled out"];
  const diagnosisTypeOpts = ["Primary Diagnosis", "Differential Diagnosis"];
  const timeOptions = ["Days", "Weeks", "Months", "Years"];
  const doseOptions = ["Tablet", "ML"];
  const timingOptions = [
    "After Meal",
    "Before Meal",
    "With Meal",
    "Empty Stomach",
    "Before breakfast",
    "After breakfast",
    "Before lunch",
    "After lunch",
    "Before dinner",
    "After dinner",
    "Along with food",
    "At bed time",
    "On waking up",
  ];

  const handleRelationshipChange = (option, newValue) => {
    handleTextFieldChange(option, "relationship", newValue);
  };

  const handleDiganosisOptionChange = (option, newValue, key) => {
    handleDiagnosisTextChange(option, key, newValue);
  };

  useEffect(() => {
    if (formValues?.bodyHeight !== "" && formValues.bodyWeight !== "") {
      const bmi = calculateBMI(formValues?.bodyHeight, formValues.bodyWeight);
      if (typeof bmi === "number") {
        setBodyMassIndex(bmi.toFixed(2));
      } else {
        setBodyMassIndex("");
      }
    }
  }, [formValues?.bodyHeight, formValues.bodyWeight]);

  const handleNumberOptions = (event, value) => {
    const isValidInput = /^([1-9]\d{0,2}(Days|Weeks|Months)?)?$/.test(value);
    if (isValidInput) {
      setNumber(value);
    }
  };

  const handleSymptomNumberOptions = (event, value) => {
    const isValidInput = /^([1-9]\d{0,2}(Days|Weeks|Months)?)?$/.test(value);
    if (isValidInput) {
      setSymptomNumber(value);
    }
  };

  const handleHistoryNumberOptions = (event, value) => {
    const isValidInput = /^([1-9]\d{0,2}(Days|Weeks|Months)?)?$/.test(value);
    if (isValidInput) {
      setMedicalHistoryNumber(value);
    }
  };
  const handleDoseOptions = (event, value) => {
    const isValidInput = /^([1-9]\d{0,2}(Tablet)?)?$/.test(value);
    if (isValidInput) {
      setDose(value);
    }
  };

  const generateOptions = (number, item) => {
    const parsedNumber = parseInt(number, 10);
    if (isNaN(parsedNumber) || !item) {
      return timeOptions;
    }
    const sinceValue = medicationsSpecs[item]?.since;
    if (sinceValue === "" || !isNaN(parsedNumber)) {
      return timeOptions?.map((option) => `${parsedNumber}${option}`) || [];
    }
    return [];
  };

  const generateSymptomsOptions = (number, item) => {
    const parsedNumber = parseInt(number, 10);
    if (isNaN(parsedNumber) || !item) {
      return [];
    }
    const sinceValue = symptomsSpecs[item]?.since;
    if (sinceValue === "" || !isNaN(parsedNumber)) {
      return timeOptions?.map((option) => `${parsedNumber}${option}`) || [];
    }
    return [];
  };

  const generateHistoryOptions = (number, item) => {
    const parsedNumber = parseInt(number, 10);
    if (isNaN(parsedNumber) || !item) {
      return [];
    }
    const sinceValue = optionTextValues[item]?.since;
    if (sinceValue === "" || !isNaN(parsedNumber)) {
      return timeOptions?.map((option) => `${parsedNumber}${option}`) || [];
    }
    return [];
  };

  const generateSymptomsOptionChange = (option, newValue, key) => {
    handleSymtomsTextChange(option, key, newValue);
  };

  const generateMedicalHistoryOptionChange = (option, newValue) => {
    handleTextFieldChange(option, "since", newValue);
  };

  const generateDoseOptions = (number, item) => {
    const parsedNumber = parseInt(number, 10);
    if (isNaN(parsedNumber) || !item) {
      return doseOptions;
    }
    const sinceValue = medicationsSpecs[item]?.dose || "";
    if (sinceValue === "" || !isNaN(parsedNumber)) {
      return doseOptions?.map((option) => `${parsedNumber}${option}`) || [];
    }
    return [];
  };

  const handleMedicationOptionsChange = (option, newValue, key) => {
    handleMedicationsTextChange(option, key, newValue);
  };

  const handleSymptomNameChange = (event, prevValue) => {
    let newValue = event.target.value;
    if (newValue === "") {
      newValue = " ";
    }
    const updatedSymptom = symptoms.map((symptom) => {
      if (symptom.label === prevValue) {
        return { label: newValue, value: newValue };
      }
      return symptom;
    });
    setSymptoms(updatedSymptom);

    let details;
    Object.entries(symptomsSpecs).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedSymptomSpecs = {
      ...symptomsSpecs,
      [newValue]: details,
    };
    setSymptomsSpecs((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } = updatedSymptomSpecs;
      return restOptionTextValues;
    });
  };

  const handleMedicalHistoryNameChange = (event, prevValue) => {
    let newValue = event.target.value;
    if (newValue === "") {
      newValue = " ";
    }
    const updatedMedicalHistory = medicalHistory.map((history) => {
      if (history.label === prevValue) {
        return { label: newValue, value: newValue };
      }
      return history;
    });
    setMedicalHistory(updatedMedicalHistory);
    let details;
    Object.entries(optionTextValues).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedMedicalHistorySpecs = {
      ...optionTextValues,
      [newValue]: details,
    };
    setOptionTextValues((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } =
        updatedMedicalHistorySpecs;
      return restOptionTextValues;
    });
  };

  const handleExaminationFindingsNameChange = (event, prevValue) => {
    let newValue = event.target.value;
    if (newValue === "") {
      newValue = " ";
    }
    const updatedExamFindings = examFindings.map((findings) => {
      if (findings.label === prevValue) {
        return { label: newValue, value: newValue };
      }
      return findings;
    });
    setExamFinding(updatedExamFindings);

    let details;
    Object.entries(examinationSpecs).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedExaminationSpecs = {
      ...examinationSpecs,
      [newValue]: details,
    };
    setExaminationSpecs((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } =
        updatedExaminationSpecs;
      return restOptionTextValues;
    });
  };

  const handleDiagnosisNameChange = (event, prevValue) => {
    let newValue = event.target.value;
    if (newValue === "") {
      newValue = " ";
    }
    const updatedDiagnosis = diagnosis.map((item) => {
      if (item.label === prevValue) {
        return { label: newValue, value: newValue };
      }
      return item;
    });
    setDiagnosis(updatedDiagnosis);

    let details;
    Object.entries(diagnosisSpecs).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedDiagnosisSpecs = {
      ...diagnosisSpecs,
      [newValue]: details,
    };
    setDiagnosisSpecs((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } = updatedDiagnosisSpecs;
      return restOptionTextValues;
    });
  };

  const handleLabInvestigationNameChange = (event, prevValue) => {
    let newValue = event.target.value;
    if (newValue === "") {
      newValue = " ";
    }
    const updatedLabInvestigation = labInvestigation.map((investigation) => {
      if (investigation.label === prevValue) {
        return { label: newValue, value: newValue };
      }
      return investigation;
    });
    setLabInvestigation(updatedLabInvestigation);

    let details;
    Object.entries(labInvestigationSpecs).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedLabInvestigationSpecs = {
      ...labInvestigationSpecs,
      [newValue]: details,
    };
    setLabInvestigationSpecs((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } =
        updatedLabInvestigationSpecs;
      return restOptionTextValues;
    });
  };

  const handleMedicineNameChange = (prevValue, newValue) => {
    let medicineName = newValue;
    if (medicineName === "") {
      medicineName = " ";
    }
    if (medicineName?.label) {
      medicineName = medicineName?.label;
    }
    const updatedMedicine = medications.map((medicine) => {
      if (medicine.label === prevValue) {
        return { label: medicineName, value: medicineName };
      }
      return medicine;
    });
    setMedications(updatedMedicine);

    let details;
    Object.entries(medicationsSpecs).map(([label, value]) => {
      if (label === prevValue) {
        details = value;
      }
    });
    const updatedMedicationSpecs = {
      ...medicationsSpecs,
      [medicineName]: details,
    };
    setMedicationsSpecs((prevState) => {
      const { [prevValue]: _, ...restOptionTextValues } =
        updatedMedicationSpecs;
      return restOptionTextValues;
    });
  };

  // Notes Popup Settings
  const [openComplaintNotes, setOpenComplaintNotes] = useState(false);
  const handleOpenComplaintNotes = () => setOpenComplaintNotes(true);
  const handleCloseComplaintNotes = () => setOpenComplaintNotes(false);

  const [openMedicalHistory, setOpenMedicalHistory] = useState(false);
  const handleOpenMedicalHistory = () => setOpenMedicalHistory(true);
  const handleCloseMedicalHistory = () => setOpenMedicalHistory(false);

  const [openFindingNotes, setOpenFindingNotes] = useState(false);
  const handleOpenFindingNotes = () => setOpenFindingNotes(true);
  const handleCloseFindingNotes = () => setOpenFindingNotes(false);

  const [openDiagnosisNotes, setOpenDiagnosisNotes] = useState(false);
  const handleOpenDiagnosisNotes = () => setOpenDiagnosisNotes(true);
  const handleCloseDiagnosisNotes = () => setOpenDiagnosisNotes(false);

  const [openLabNotes, setOpenLabNotes] = useState(false);
  const handleOpenLabNotes = () => setOpenLabNotes(true);
  const handleCloseLabNotes = () => setOpenLabNotes(false);

  const [openVaccineNotes, setOpenVaccineNotes] = useState(false);
  const handleOpenVaccineNotes = () => setOpenVaccineNotes(true);
  const handleCloseVaccineNotes = () => setOpenVaccineNotes(false);

  const [openMedicationNotes, setOpenMedicationNotes] = useState(false);
  const handleOpenMedicationNotes = () => setOpenMedicationNotes(true);
  const handleCloseMedicationNotes = () => setOpenMedicationNotes(false);

  const formattedValues = () => {
    const symptomsEMR = symptomObj(symptomsSpecs);
    const diagnosisEMR = diagnosisObj(diagnosisSpecs);
    const examinEMR = diseaseObject(examinationSpecs);
    const medicationEMR = medicationObj(medicationsSpecs);
    const currentMedicationEMR = currentMedicationObj();
    const labInvestigationEMR = labInvestigationObj(labInvestigationSpecs);
    const medicalHistoryEMR = medicalHistoryObj(optionTextValues);
    const vaccinationRecordEMR = vaccinationRecordObj(vaccineRecordSpecs);

    const payloadArr = [
      {
        key: "vital",
        dataArr: {
          height: formValues?.bodyHeight,
          weight: formValues?.bodyWeight,
          pulse: formValues?.pulseRate,
          blood_pressure: formValues?.bloodPressure,
          body_temperature: formValues?.bodyTemp,
          oxygen_saturation: formValues?.oxygenSaturation,
          respiratory_rate: formValues?.respiratoryRate,
          systolic_blood_pressure: formValues?.systolicBP,
          diastolic_blood_pressure: formValues?.diastolicaBP,
          body_mass_index: bodyMassIndex,
          head_circumference: formValues?.headCircumference,
        },
      },
      // {
      //   key: "condition",
      //   dataArr: conditionEMR,
      // },
      {
        key: "examination_findings",
        dataArr: examinEMR,
      },
      {
        key: "symptom",
        dataArr: symptomsEMR,
      },
      {
        key: "diagnosis",
        dataArr: diagnosisEMR,
      },
      {
        key: "medication",
        dataArr: medicationEMR,
      },
      {
        key: "lab_investigation",
        dataArr: labInvestigationEMR,
      },
      {
        key: "vaccination_record",
        dataArr: vaccinationRecordEMR,
      },
      {
        key: "medical_history",
        dataArr: medicalHistoryEMR,
      },
    ];

    payloadArr?.forEach((item) => {
      createPayload(item?.key, item?.dataArr);
    });

    const formattedData = submitEMRPayload;
    formattedData["advice"] = advices
      ? advices.join(" | ").replace(/\n/g, " | ")
      : "";
    formattedData["notes"] = prescriptionComment
      ? prescriptionComment.join(" | ").replace(/\n/g, " | ")
      : "";
    formattedData["treatment_plan"] = treatmentPlan
      ? treatmentPlan.join(" | ").replace(/\n/g, " | ")
      : "";
    return formattedData;
  };

  useImperativeHandle(emrValueRef, () => {
    return {
      formattedValues,
    };
  });

  const handleWheel = (event) => {
    event.target.blur();
  };

  const handleRowFocus = (rowIndex) => {
    setOpenRowIndex(rowIndex);
  };

  const handleRowBlur = () => {
    setOpenRowIndex(null);
  };

  return (
    <>
      {isEMR && (userRole === "DOCTOR" || userRole === "ADMIN") && (
        <RecorderComponent
          summaryContent={PMRSummarisedNotes}
          setSummaryContent={setPMRSummarisedNotes}
          isTypeAudio={isTypeAudio}
          setIsTypeAudio={setIsTypeAudio}
          responseStructure={responseStructure}
          setResponseStructure={setResponseStructure}
          clearForm={resetEMRForm}
        />
      )}
      <EMRFormInnerWrapper>
        {isEMR && (
          <VitalsContainer>
            <SectionHeader>Vitals</SectionHeader>
            <form>
              <Grid container spacing={{ xs: 6, md: 8 }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Pulse Rate</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        onWheel={handleWheel}
                        variant="outlined"
                        name="pulseRate"
                        value={formValues.pulseRate}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>/min</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">
                    Peripheral oxygen saturation
                  </Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        onWheel={handleWheel}
                        variant="outlined"
                        name="oxygenSaturation"
                        value={formValues.oxygenSaturation}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>%</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Blood Pressure</Typography>
                  <BPTextFieldWrapper>
                    <Grid item xs={8}>
                      {formValues?.bloodPressure ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          onWheel={handleWheel}
                          name="bloodPressure"
                          value={formValues.bloodPressure}
                          onChange={handleInputChange}
                          className="emr-input-field"
                        />
                      ) : (
                        <BPWrapper>
                          <SystolicTextField
                            fullWidth
                            type="number"
                            variant="outlined"
                            onWheel={handleWheel}
                            name="systolicBP"
                            value={formValues.systolicBP}
                            onChange={handleInputChange}
                            className="emr-input-field"
                          />
                          <Divider>/</Divider>
                          <DiastolicTextField
                            fullWidth
                            type="number"
                            variant="outlined"
                            onWheel={handleWheel}
                            name="diastolicaBP"
                            value={formValues.diastolicaBP}
                            onChange={handleInputChange}
                            className="emr-input-field"
                          />
                        </BPWrapper>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>mmHg</VitalValue>
                    </Grid>
                  </BPTextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Respiratory rate</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        onWheel={handleWheel}
                        name="respiratoryRate"
                        value={formValues.respiratoryRate}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>/min</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Body Temperature</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        onWheel={handleWheel}
                        name="bodyTemp"
                        value={formValues.bodyTemp}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>°C</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Body height</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        onWheel={handleWheel}
                        name="bodyHeight"
                        value={formValues.bodyHeight}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>Cms</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Body weight</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        onWheel={handleWheel}
                        name="bodyWeight"
                        value={formValues.bodyWeight}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>Kgs</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Body mass index</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <BPWrapper>
                        <RowWrapper>{bodyMassIndex}</RowWrapper>
                      </BPWrapper>
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>Kg/m2</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">Heart Rate</Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        onWheel={handleWheel}
                        name="heartRate"
                        value={formValues.heartRate}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>bpm</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle1">
                    Head Circumference
                  </Typography>
                  <TextFieldWrapper>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        onWheel={handleWheel}
                        name="headCircumference"
                        value={formValues.headCircumference}
                        onChange={handleInputChange}
                        className="emr-input-field"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <VitalValue>cm</VitalValue>
                    </Grid>
                  </TextFieldWrapper>
                </Grid>
              </Grid>
            </form>
          </VitalsContainer>
        )}
        {(userRole === "DOCTOR" || userRole === "ADMIN") && (
          <>
            <VitalsContainer>
              <SectionHeader>Complaints</SectionHeader>
              <CustomAutoComplete
                placeholder="Enter text"
                options={symptomsOpts}
                handleInputChange={handleSymptompsChange}
                setOptions={setSymptomsOpts}
                handleOptionChange={handleSymptoms}
                handleClearOptions={clearSymptomsOptions}
              />
              {symptoms?.length > 0 && (
                <div>
                  {symptoms
                    ?.slice(0)
                    .reverse()
                    .map((item, index) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer key={index}>
                          <RecordLayout
                            placeholder="Name"
                            value={item?.label || item}
                            onChange={(e) =>
                              handleSymptomNameChange(e, item?.label || item)
                            }
                          />
                          <TextBoxLayout className="addMinWidth">
                            <Autocomplete
                              className="sinceAutocomplete"
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={generateSymptomsOptions(
                                symptomNumber,
                                item
                              )}
                              value={symptomsSpecs[item?.label]?.since || ""}
                              onChange={(e, newValue) =>
                                generateSymptomsOptionChange(
                                  item,
                                  newValue,
                                  "since"
                                )
                              }
                              // inputValue={symptomNumber}
                              onInputChange={(e, newValue) =>
                                handleSymptomNumberOptions(item, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="tel"
                                  label="Since"
                                  variant="outlined"
                                  placeholder="since"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                placeholder="Notes"
                                value={symptomsSpecs[item?.label]?.notes || ""}
                                onChange={(e) =>
                                  handleSymtomsTextChange(
                                    item,
                                    "notes",
                                    e.target.value
                                  )
                                }
                                label="Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <p
                              onClick={handleOpenComplaintNotes}
                              className="mobile"
                            >
                              <NotesField />
                            </p>
                            <Modal
                              open={openComplaintNotes}
                              onClose={handleCloseComplaintNotes}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Complaints Note
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseComplaintNotes}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography sx={{ mt: 2 }}>
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        symptomsSpecs[item?.label]?.notes || ""
                                      }
                                      onChange={(e) =>
                                        handleSymtomsTextChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseComplaintNotes}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>

                            <DeleteField
                              onClick={handleSymptomsSpecsDelete(item?.label)}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>
            <VitalsContainer>
              <SectionHeader>Patient Medical History</SectionHeader>
              <CustomAutoComplete
                placeholder="Enter text"
                options={medicalHistoryOpts}
                handleInputChange={handleMeidcalHistoryChange}
                setOptions={setMedicalHistoryOpts}
                handleOptionChange={handleMedicalHistoryValue}
                handleClearOptions={clearMedicalHistoryOptions}
                autocompleteRef={medicalHistoryRef}
              />
              {medicalHistory?.length > 0 && (
                <div>
                  {medicalHistory
                    ?.slice(0)
                    .reverse()
                    .map((item) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer>
                          <RecordLayout
                            placeholder="Name"
                            value={item?.label || item}
                            onChange={(e) =>
                              handleMedicalHistoryNameChange(
                                e,
                                item?.label || item
                              )
                            }
                          />
                          <TextBoxLayout className="addMinWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={generateHistoryOptions(
                                medicalHistoryNumber,
                                item
                              )}
                              value={optionTextValues[item?.label]?.since || ""}
                              onChange={(e, newValue) =>
                                generateMedicalHistoryOptionChange(
                                  item,
                                  newValue
                                )
                              }
                              // inputValue={symptomNumber}
                              onInputChange={(e, newValue) =>
                                handleHistoryNumberOptions(item, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  type="tel"
                                  {...params}
                                  label="Since"
                                  placeholder="since"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="addMinWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={relationshipOptions}
                              value={
                                optionTextValues[item?.label]?.relationship ||
                                ""
                              }
                              onChange={(e, newValue) =>
                                handleRelationshipChange(item, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Relationship"
                                  placeholder="relationship"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                placeholder="Notes"
                                value={
                                  optionTextValues[item?.label]?.notes || ""
                                }
                                onChange={(e) =>
                                  handleTextFieldChange(
                                    item,
                                    "notes",
                                    e.target.value
                                  )
                                }
                                label="Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <p
                              onClick={handleOpenMedicalHistory}
                              className="mobile"
                            >
                              <NotesField />
                            </p>
                            <Modal
                              open={openMedicalHistory}
                              onClose={handleCloseMedicalHistory}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Patient Medical History Notes
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseMedicalHistory}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        optionTextValues[item?.label]?.notes ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleTextFieldChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseMedicalHistory}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>
                            <DeleteField
                              onClick={handleOptionRemove(item?.label)}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>
            <VitalsContainer>
              <SectionHeader>Examination Findings</SectionHeader>
              <CustomAutoComplete
                placeholder="Enter text"
                options={examFindingsOpts}
                handleInputChange={handleExamFindingsChange}
                setOptions={setExamFindingsOpts}
                handleOptionChange={handleExaminationFindings}
                handleClearOptions={clearExaminationFindingOptions}
              />
              {examFindings?.length > 0 && (
                <div>
                  {examFindings
                    ?.slice(0)
                    .reverse()
                    .map((item) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer>
                          <RecordLayout
                            placeholder="Name"
                            value={item?.label || item}
                            onChange={(e) =>
                              handleExaminationFindingsNameChange(
                                e,
                                item?.label || item
                              )
                            }
                          />
                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                placeholder="Notes"
                                value={
                                  examinationSpecs[item?.label]?.notes || ""
                                }
                                onChange={(e) =>
                                  handleExaminationTextChange(
                                    item,
                                    "notes",
                                    e.target.value
                                  )
                                }
                                label="Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <p
                              onClick={handleOpenFindingNotes}
                              className="mobile"
                            >
                              <NotesField />
                            </p>
                            <Modal
                              open={openFindingNotes}
                              onClose={handleCloseFindingNotes}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Examination Finding Notes
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseFindingNotes}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        examinationSpecs[item?.label]?.notes ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleExaminationTextChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseFindingNotes}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>
                            <DeleteField
                              onClick={handleExaminationSpecsDelete(
                                item?.label
                              )}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>
            <VitalsContainer>
              <SectionHeader>Diagnosis</SectionHeader>
              <CustomAutoComplete
                placeholder="Enter text"
                options={diagnosisOpts}
                handleInputChange={handleDiagnosisChange}
                setOptions={setDiagnosisOpts}
                handleOptionChange={handleDiagnosis}
                handleClearOptions={clearDiagnosisOptions}
              />
              {diagnosis?.length > 0 && (
                <div>
                  {diagnosis
                    ?.slice(0)
                    .reverse()
                    .map((item) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer>
                          <RecordLayout
                            placeholder="Name"
                            value={item?.label || item}
                            onChange={(e) =>
                              handleDiagnosisNameChange(e, item?.label || item)
                            }
                          />
                          <TextBoxLayout className="addMinWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={diagnosisStatusOpts}
                              value={diagnosisSpecs[item?.label]?.since || ""}
                              onChange={(e, newValue) =>
                                handleDiganosisOptionChange(
                                  item,
                                  newValue,
                                  "since"
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status"
                                  placeholder="diagnosis status"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="addMinWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={diagnosisTypeOpts}
                              value={
                                diagnosisSpecs[item?.label]?.severity || ""
                              }
                              onChange={(e, newValue) =>
                                handleDiganosisOptionChange(
                                  item,
                                  newValue,
                                  "severity"
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type"
                                  placeholder="diagnosis type"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                placeholder="Notes"
                                value={diagnosisSpecs[item?.label]?.notes || ""}
                                onChange={(e) =>
                                  handleDiagnosisTextChange(
                                    item,
                                    "notes",
                                    e.target.value
                                  )
                                }
                                label="Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <p
                              onClick={handleOpenDiagnosisNotes}
                              className="mobile"
                            >
                              <NotesField />
                            </p>
                            <Modal
                              open={openDiagnosisNotes}
                              onClose={handleCloseDiagnosisNotes}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Diagnosis Notes
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseDiagnosisNotes}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        diagnosisSpecs[item?.label]?.notes || ""
                                      }
                                      onChange={(e) =>
                                        handleDiagnosisTextChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseDiagnosisNotes}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>
                            <DeleteField
                              onClick={handleDiagnosisSpecsDelete(item?.label)}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>
            <VitalsContainer>
              <SectionHeader>Lab Investigations</SectionHeader>
              <CustomAutoComplete
                placeholder="Enter text"
                options={labInvestigationsOpts}
                handleInputChange={handleLabInvestigationsChange}
                setOptions={setLabInvestigationsOpts}
                handleOptionChange={handleLabInvestigations}
                handleClearOptions={clearLabInvestigationOptions}
              />
              {labInvestigation?.length > 0 && (
                <div>
                  {labInvestigation
                    ?.slice(0)
                    .reverse()
                    .map((item) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer>
                          <RecordLayout
                            placeholder="Name"
                            value={item?.label || item}
                            onChange={(e) =>
                              handleLabInvestigationNameChange(
                                e,
                                item?.label || item
                              )
                            }
                          />
                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                placeholder="Notes"
                                value={
                                  labInvestigationSpecs[item?.label]?.notes ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleLabTextChange(
                                    item,
                                    "notes",
                                    e.target.value
                                  )
                                }
                                label="Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <p onClick={handleOpenLabNotes} className="mobile">
                              <NotesField />
                            </p>
                            <Modal
                              open={openLabNotes}
                              onClose={handleCloseLabNotes}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Lab Investigation Notes
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseLabNotes}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        labInvestigationSpecs[item?.label]
                                          ?.notes || ""
                                      }
                                      onChange={(e) =>
                                        handleLabTextChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseLabNotes}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>
                            <DeleteField
                              onClick={handleLabSpecsDelete(item?.label)}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>

            {/* VACINATION RECORD */}
            <VitalsContainer>
              <SectionHeader>Vaccination Record</SectionHeader>
              <CustomAutoComplete
                placeholder="Enter text"
                options={vaccinationOpts}
                handleInputChange={handleVaccinationRecordChange}
                setOptions={setVaccinationOpts}
                handleOptionChange={handleVaccineRecords}
                // handleClearOptions={clearVaccinationRecordOptions}
              />
              {vaccineRecord?.length > 0 && (
                <div>
                  {vaccineRecord
                    ?.slice(0)
                    .reverse()
                    .map((item) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer>
                          <RecordLayout
                            placeholder="Name"
                            value={item?.label || item}
                            onChange={(e) =>
                              handleVaccineRecordNameChange(
                                e,
                                item?.label || item
                              )
                            }
                          />
                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                placeholder="Lot Number"
                                value={
                                  vaccineRecordSpecs[item?.label]?.lot_number ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleVaccineRecordTextChange(
                                    item,
                                    "lot_number",
                                    e.target.value
                                  )
                                }
                                label="Lot Number"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <p
                              onClick={handleOpenVaccineNotes}
                              className="mobile"
                            >
                              <NotesField />
                            </p>
                            <Modal
                              open={openVaccineNotes}
                              onClose={handleCloseVaccineNotes}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Lab Investigation Notes
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseVaccineNotes}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        vaccineRecordSpecs[item?.label]
                                          ?.notes || ""
                                      }
                                      onChange={(e) =>
                                        handleVaccineRecordTextChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseVaccineNotes}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>
                            <DeleteField
                              onClick={handleVaccineSpecsDelete(item?.label)}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>

            {/* */}
            <VitalsContainer>
              <SectionHeader>Medications</SectionHeader>
              <MedicationAutoComplete
                placeholder="Enter text"
                options={medicationsOpts}
                handleInputChange={handleMedicationsChange}
                setOptions={setMedicationsOpts}
                handleOptionChange={handleMedications}
                handleClearOptions={clearMedicationOptions}
                medicationInputValue={medicationInputValue}
              />
              {medications?.length > 0 && (
                <div>
                  {medications
                    ?.slice(0)
                    .reverse()
                    .map((item, index) => (
                      <div
                        className={
                          item?.type === "audio"
                            ? "styling_audio_details"
                            : item?.type === "template"
                            ? "styling_template_details"
                            : ""
                        }
                      >
                        <FieldSpecsContainer
                          className="desktopTextBoxLayout"
                          key={index}
                          onFocus={() => handleRowFocus(index)}
                          onBlur={handleRowBlur}
                        >
                          <Autocomplete
                            style={{ flex: 1 }}
                            freeSolo
                            forcePopupIcon={false}
                            disableClearable={true}
                            options={medicationsOpts}
                            autoSelect={true}
                            filterSelectedOptions
                            value={item?.label || item}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Medicine"
                                variant="outlined"
                                onChange={handleMedicationsChange}
                              />
                            )}
                            onChange={(event, newInputValue) =>
                              handleMedicineNameChange(
                                item?.label || item,
                                newInputValue
                              )
                            }
                            // value={item?.label || item}
                            blurOnSelect={true}
                            clearOnBlur={true}
                            onBlur={clearMedicationOptions}
                          />
                          <TextBoxLayout style={{ maxWidth: "80px" }}>
                            <RecordTextField
                              placeholder="0-0-0"
                              value={
                                medicationsSpecs[item?.label]?.severity || ""
                              }
                              onChange={(e) =>
                                handleMedicationsTextChange(
                                  item,
                                  "severity",
                                  e.target.value
                                )
                              }
                              type="tel"
                              inputProps={{ maxLength: 5 }}
                              label="Frequency"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          </TextBoxLayout>
                          <TextBoxLayout style={{ maxWidth: "135px" }}>
                            <Autocomplete
                              open={openRowIndex === index}
                              onOpen={() => handleRowFocus(index)}
                              onClose={handleRowBlur}
                              disableCloseOnSelect={true}
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={timingOptions} // Replace with your actual timing options
                              value={medicationsSpecs[item?.label]?.timing}
                              onChange={(event, newValue) =>
                                handleMedicationsTextChange(
                                  item,
                                  "timing",
                                  newValue
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Timing"
                                  placeholder="time of day"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="addMinWidth addMaxWidth">
                            <Autocomplete
                              open={openRowIndex === index}
                              onOpen={() => handleRowFocus(index)}
                              onClose={handleRowBlur}
                              disableCloseOnSelect={true}
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={generateDoseOptions(dose, item)}
                              getOptionDisabled={(option) =>
                                doseOptions.includes(option)
                              }
                              value={medicationsSpecs[item?.label]?.dose || ""}
                              onChange={(e, newValue) =>
                                handleMedicationOptionsChange(
                                  item,
                                  newValue,
                                  "dose"
                                )
                              }
                              // inputValue={dose}
                              onInputChange={(e, newVal) =>
                                handleDoseOptions(e, newVal)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="tel"
                                  label="Dose"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  placeholder="1Tablet"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="addMinWidth addMaxWidth">
                            <Autocomplete
                              open={openRowIndex === index}
                              onOpen={() => handleRowFocus(index)}
                              onClose={handleRowBlur}
                              disableCloseOnSelect={true}
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={generateOptions(number, item)}
                              getOptionDisabled={(option) =>
                                timeOptions.includes(option)
                              }
                              value={medicationsSpecs[item?.label]?.since || ""}
                              onChange={(e, newValue) =>
                                handleMedicationOptionsChange(
                                  item,
                                  newValue,
                                  "since"
                                )
                              }
                              // inputValue={number}
                              onInputChange={(e, newValue) =>
                                handleNumberOptions(item, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="tel"
                                  label="Duration"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  placeholder="1Day"
                                />
                              )}
                            />
                          </TextBoxLayout>

                          <NotesWrapper>
                            <TextBoxLayout className="desktop">
                              <RecordTextField
                                multiline
                                maxRows={3}
                                placeholder="Notes"
                                value={
                                  medicationsSpecs[item?.label]?.notes || ""
                                }
                                onChange={(e) =>
                                  handleMedicationsTextChange(
                                    item,
                                    "notes",
                                    e.target.value
                                  )
                                }
                                label="Notes"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </TextBoxLayout>
                          </NotesWrapper>
                          <DeleteWrapper>
                            <DeleteField
                              onClick={handleMedicationsSpecsDelete(
                                item?.label
                              )}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>

                        <FieldSpecsContainer
                          className="mobileTextBoxLayout"
                          key={index}
                          onFocus={() => handleRowFocus(index)}
                          onBlur={handleRowBlur}
                        >
                          <Autocomplete
                            style={{ flex: 1 }}
                            freeSolo
                            forcePopupIcon={false}
                            disableClearable={true}
                            options={medicationsOpts}
                            autoSelect={true}
                            filterSelectedOptions
                            value={item?.label || item}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Medicine"
                                variant="outlined"
                                onChange={handleMedicationsChange}
                              />
                            )}
                            onChange={(event, newInputValue) =>
                              handleMedicineNameChange(
                                item?.label || item,
                                newInputValue
                              )
                            }
                            // value={item?.label || item}
                            blurOnSelect={true}
                            clearOnBlur={true}
                            onBlur={clearMedicationOptions}
                          />
                          <TextBoxLayout className="addMinWidth addMaxWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={generateDoseOptions(dose, item)}
                              value={medicationsSpecs[item?.label]?.dose || ""}
                              onChange={(e, newValue) =>
                                handleMedicationOptionsChange(
                                  item,
                                  newValue,
                                  "dose"
                                )
                              }
                              // inputValue={dose}
                              onInputChange={(e, newVal) =>
                                handleDoseOptions(e, newVal)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="tel"
                                  label="Dose"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  placeholder="1Tablet"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="addMinWidth addMaxWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={generateOptions(number, item)}
                              value={medicationsSpecs[item?.label]?.since || ""}
                              onChange={(e, newValue) =>
                                handleMedicationOptionsChange(
                                  item,
                                  newValue,
                                  "since"
                                )
                              }
                              // inputValue={number}
                              onInputChange={(e, newValue) =>
                                handleNumberOptions(item, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="tel"
                                  label="Duration"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  placeholder="1Day"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="mobileTextBoxLayout frequencyInput">
                            <RecordTextField
                              placeholder="0-0-0"
                              type="tel"
                              value={
                                medicationsSpecs[item?.label]?.severity || ""
                              }
                              onChange={(e) =>
                                handleMedicationsTextChange(
                                  item,
                                  "severity",
                                  e.target.value
                                )
                              }
                              inputProps={{ maxLength: 5 }}
                              label="Frequency"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          </TextBoxLayout>
                          <TextBoxLayout className="mobileTextBoxLayout addMaxWidth">
                            <Autocomplete
                              forcePopupIcon={false}
                              disableClearable={true}
                              options={timingOptions} // Replace with your actual timing options
                              value={
                                medicationsSpecs[item?.label]?.timing || ""
                              }
                              onChange={(event, newValue) =>
                                handleMedicationsTextChange(
                                  item,
                                  "timing",
                                  newValue
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Timing"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </TextBoxLayout>
                          <DeleteWrapper>
                            <p
                              onClick={handleOpenMedicationNotes}
                              className="mobile"
                            >
                              <NotesField />
                            </p>
                            <Modal
                              open={openMedicationNotes}
                              onClose={handleCloseMedicationNotes}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div style={{ position: "relative" }}>
                                  <Toolbar>
                                    <Typography
                                      id="modal-modal-title"
                                      sx={{ flex: 1 }}
                                      variant="h3"
                                    >
                                      Medication Notes
                                    </Typography>
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      onClick={handleCloseMedicationNotes}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Toolbar>
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  <TextBoxLayout>
                                    <TextField
                                      multiline
                                      maxRows={3}
                                      className="textareaAutoSizeStyle"
                                      placeholder="Notes"
                                      value={
                                        medicationsSpecs[item?.label]?.notes ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleMedicationsTextChange(
                                          item,
                                          "notes",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </TextBoxLayout>
                                </Typography>
                                <PrimaryButton
                                  onClick={handleCloseMedicationNotes}
                                  sx={{
                                    marginTop: "10px",
                                    float: "right",
                                  }}
                                >
                                  Submit
                                </PrimaryButton>
                              </Box>
                            </Modal>
                            <DeleteField
                              onClick={handleMedicationsSpecsDelete(
                                item?.label
                              )}
                            >
                              Delete
                            </DeleteField>
                          </DeleteWrapper>
                        </FieldSpecsContainer>
                      </div>
                    ))}
                </div>
              )}
            </VitalsContainer>

            <VitalsContainer>
              <SectionHeader>Treatment Plan</SectionHeader>
              <TextField
                multiline
                minRows={4}
                maxRows={4}
                fullWidth
                className="textareaAutoSizeStyle"
                placeholder="Add treatment plan"
                onChange={treatmentPlanChange}
                value={treatmentPlan.join("\n")}
              />
            </VitalsContainer>

            {isEMR && (
              <VitalsContainer>
                <SectionHeader>Follow Up</SectionHeader>
                <TextField
                  sx={{ width: "100%" }}
                  placeholder="Select Date"
                  type="date"
                  inputProps={{
                    min: format(new Date(), "yyyy-MM-dd"), // Set max date to the current date
                  }}
                  value={followUp}
                  onChange={handleDateChange}
                />
              </VitalsContainer>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <VitalsContainer>
                  <SectionHeader>Notes</SectionHeader>
                  <TextField
                    multiline
                    minRows={6}
                    maxRows={6}
                    fullWidth
                    className="textareaAutoSizeStyle"
                    placeholder="Add your notes here"
                    onChange={prescriptionCommentChange}
                    value={prescriptionComment.join("\n")}
                  />
                </VitalsContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <VitalsContainer>
                  <SectionHeader>Advices</SectionHeader>
                  <TextField
                    multiline
                    minRows={6}
                    maxRows={6}
                    fullWidth
                    className="textareaAutoSizeStyle"
                    placeholder="Add your advices here"
                    onChange={adviceChange}
                    value={advices.join("\n")}
                  />
                </VitalsContainer>
              </Grid>
            </Grid>
          </>
        )}
      </EMRFormInnerWrapper>
    </>
  );
}
